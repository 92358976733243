/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: { currentUser?: API.CurrentUser | undefined }) {
  const { currentUser } = initialState || {};

  const admins = ['partnerAdmin'];

  // 受限路由，只有渠道端可以看到
  // const limitRoute = ['/risk-control/channel-support-docs'];

  // if (conf.env === 'beta' || conf.env === 'dev') {
  //   admins.push('管理员');
  //   admins.push('beta');
  // }

  if (currentUser && currentUser.forbidden) {
    return {
      canPartnerView: false,
      canAccess: false,
      canPartnerViewAndAdmin: false,
      canNotPartnerView: false,
    };
  }

  const isAdmin = currentUser && admins.filter((x) => currentUser.roles?.includes(x)).length > 0;

  return {
    canPartnerView: (route: any) => {
      return (
        currentUser &&
        (currentUser.roles?.includes('partnerView') || currentUser.roles?.includes(route.path))
      );
    },
    canAccess: (route: any) => {
      return (currentUser && currentUser.roles?.includes(route.path)) || isAdmin;
    },

    canPaetnerAdmin: () => {
      return isAdmin;
    },
    canPartnerTransfer: () => {
      return currentUser && currentUser.roles?.includes('partnerTransfer');
    },

    canPartnerViewAndAdmin: (route: any) => {
      return (
        currentUser &&
        (currentUser.roles?.includes('partnerView') ||
          currentUser.roles?.includes(route.path) ||
          isAdmin)
      );
    },

    canNotPartnerView: (route: any) => {
      // 新注册用户登陆 跳转到公司管理菜单时需要鉴权 防止没有权限403
      if (!currentUser || !currentUser.roles) {
        return true;
      }

      return (
        !currentUser.roles?.includes('partnerView') ||
        currentUser.roles?.includes(route.path) ||
        isAdmin
      );
    },
  };
}

import { Space } from 'antd';
import React from 'react';
import { useModel, SelectLang } from 'umi';
import Avatar from './AvatarDropdown';
import styles from './index.less';

export type SiderTheme = 'light' | 'dark';

const GlobalHeaderRight: React.FC = () => {
  const { initialState } = useModel('@@initialState');

  if (!initialState || !initialState.settings) {
    return null;
  }

  const { navTheme, layout } = initialState.settings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right}  ${styles.dark}`;
  }
  return (
    <Space className={className}>
      <Avatar />
      <SelectLang
        className={styles.action}
        // reload={false}
        postLocalesData={(LocalData) =>
          LocalData.filter((localeObj) => {
            const langArr = ['en-US', 'zh-CN'];
            return langArr.includes(localeObj.lang);
          })
        }
      />
    </Space>
  );
};
export default GlobalHeaderRight;

import { useState } from 'react';
import { GetApiMerchantCaseBatchStatusResponse } from '@/services/ytt_apis/apiMerchant/case';

export default function useAddCasesDataSource() {
  const [dataSource, setDataSource] = useState<GetApiMerchantCaseBatchStatusResponse['data']>({});

  return {
    dataSource: dataSource || {},
    updateDataSource: setDataSource,
  };
}

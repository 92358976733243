export default {
  "menu.wallets": "Wallets",
  "menu.wallets.balance": "Balance",
  "menu.wallets.balance.detail": "Balance detail",
  "menu.wallets.balance.deposit": "Deposit",
  "menu.wallets.balance.exchange": "Exchange",
  "menu.wallets.balance.notifications": "Notifications",
  "menu.transfers": "Transfers",
  "menu.transfers.single": "Single Transfer",
  "menu.transfers.batch": "Batch Transfer",
  "menu.batchTransfers": "Batch Transfer",
  "menu.batchTransfers.personal": "Personal Payout transfer",
  "menu.batchTransfers.business": "Business Payout transfer",
  "menu.personalTransactions.batch": "Awaiting Transfer",
  "menu.personalTransactions.batchTransactionList": "Batch Transaction List",
  "menu.personalTransactions": "Personal Payout Transactions",
  "menu.personalTransactions.balance": "Balance Transactions",
  "menu.personalTransactions.balanceDetail": "Merchant Order Detail",
  "menu.personalTransactions.transfer": "Transfer Transactions",
  "menu.personalTransactions.transferDetail": "Transaction Detail",
  "menu.personalTransactions.orderSearch": "Order Search",
  "menu.businessTransactions.batch": "Awaiting Transfer",
  "menu.businessTransactions.batchTransactionList": "Batch Transaction List",
  "menu.businessTransactions": "Business Payout Transactions",
  "menu.businessTransactions.balance": "Balance Transactions",
  "menu.businessTransactions.balanceDetail": "Merchant Order Detail",
  "menu.businessTransactions.transfer": "Transaction List",
  "menu.businessTransactions.transferDetail": "Transaction Detail",
  "menu.balanceTransactions": "Balance Transactions",
  "menu.balanceTransactions.balance": "Balance Transactions",
  "menu.balanceTransactions.balanceDetail": "Merchant Order Detail",
  "menu.developer": "Developer",
  "menu.developer.xtransfer-payout-api": "xTransfer Payout API",
  "menu.riskControl.support-docs": "Support Docs",
  "menu.myCustomer": "My Customer",
  "menu.myCustomer.cases": "Cases Management",
  "menu.myCustomer.personalCase": "Cases Detail",
  "menu.myCustomer.businessCase": "Cases Detail",
  "menu.myCustomer.add-personal-customer": "Add Customer",
  "menu.myCustomer.add-business-customer": "Add Customer",
  "menu.myCustomer.batchUpload": "Batch Upload",
  "menu.myCustomer.batchUpload.batchRecipientUpload": "Customer  Information",
  "menu.myCustomer.batchUpload.batchProofMaterialsUpload": "Supporting Materials",
  "menu.riskControl": "Risk Control",
  "menu.esign": "Online signing",
  "menu.esign.esign-index": "Online signing",
  "menu.welcome": "Welcome to use",
  "menu.layout": "Layout",
  "menu.wallets.balance.balanceEntry": "Balance Entry",
  "menu.riskControl.channel-support-docs": "Search Support Docs",
  "menu.riskControl.channel-order-detail": "Transaction Detail",
  "menu.transfers.dispatch": "Batch Transfer",
  "menu.company-management": "Company Management",
  "menu.company-management.info": "Company Information",
  "menu.company-management.add": "Add Company",
  "menu.global-payout": "Global Payout",
  "menu.global-payout.order-list": "Order List",
  "menu.global-payout.order-detail": "Order Detail",
  "xch_00001": "Monitoring",
  "xch_00002": "Cases",
  "xch_00003": "Alerts",
  "xch_00004": "Dashboard",
  "xch_00005": "Analysis",
  "xch_00006": "Batch Screening",
  "xch_00007": "Settings",
  "xch_00008": "eKYC Settings",
  "xch_00009": "Screening Settings",
  "xch_00010": "Configure Matchable Fields",
  "xch_00011": "Configure Screening Preferences",
  "xch_00012": "Account Settings",
  "xch_00013": "Reports",
  "xch_00014": "All",
  "xch_00015": "Name, Phone Number, ID Number",
  "xch_00016": "Case Name",
  "xch_00017": "Country/Region",
  "xch_00018": "Segment",
  "xch_00019": "Status",
  "xch_00020": "Action",
  "xch_00021": "Edit",
  "xch_00022": "Download Report",
  "xch_00023": "{0}/page",
  "xch_00024": "Go to",
  "xch_00025": "Add Filter View",
  "xch_00026": "Edit Filter View",
  "xch_00027": "Created Time",
  "xch_00028": "Case ID",
  "xch_00029": "Updated Time",
  "xch_00030": "Name",
  "xch_00031": "Gender",
  "xch_00032": "Country",
  "xch_00033": "DOB",
  "xch_00034": "Relationship",
  "xch_00035": "Last Screening Time",
  "xch_00036": "Assocations",
  "xch_00037": "Assocation Information",
  "xch_00038": "Phase",
  "xch_00039": "Onboarding",
  "xch_00040": "Auto Screening",
  "xch_00041": "Review",
  "xch_00042": "Pass",
  "xch_00043": "Profile",
  "xch_00044": "Review",
  "xch_00045": "Comment",
  "xch_00046": "Identification Details",
  "xch_00047": "Any",
  "xch_00048": "Add",
  "xch_00049": "Delete",
  "xch_00050": "OK",
  "xch_00051": "Review Detail",
  "xch_00052": "Save",
  "xch_00053": "Match",
  "xch_00054": "Alert Time",
  "xch_00055": "Category",
  "xch_00056": "Reopen",
  "xch_00057": "Profile",
  "xch_00058": "Case",
  "xch_00059": "Wallets",
  "xch_00060": "Balance",
  "xch_00061": "Transfers",
  "xch_00062": "Customers",
  "xch_00063": "Transactions",
  "xch_00064": "Help",
  "xch_00065": "xCurrency Real Time Quote",
  "xch_00066": "Notifications",
  "xch_00067": "More",
  "xch_00068": "Single Transfer",
  "xch_00069": "Batch Transfer",
  "xch_00070": "Upload transfers",
  "xch_00071": "Confirm transfers",
  "xch_00072": "Pay for transfers",
  "xch_00073": "Upload your file, or download a template and then get started.",
  "xch_00074": "Download Template",
  "xch_00075": "Click or drag file to this area to upload",
  "xch_00076": "Support for a single upload. Please do not upload files that are not related to batch transfers.",
  "xch_00077": "Your batch transfer file is being uploaded and parsed, please be patient",
  "xch_00078": "Preparing your transfers",
  "xch_00079": "Preparing, it may take a little time if there are many orders. You can close this page and come back later.",
  "xch_00080": "All transfers are created successfully",
  "xch_00081": "Congratulations, all transfers have been created successfully. You can proceed to complete the payment.",
  "xch_00082": "Upload overview",
  "xch_00083": "Successfully created transfer(s)",
  "xch_00084": "Create failed transfer(s)",
  "xch_00085": "Continue",
  "xch_00086": "Cancel",
  "xch_00087": "{0} transfer(s) are failed to process",
  "xch_00088": "There are {0} failures, you can choose to ignore and continue to transfer, or you can choose to modify and re-upload.",
  "xch_00089": "Failed transfer overview",
  "xch_00090": "Download failed transfers",
  "xch_00091": "Row",
  "xch_00092": "Merchant Order No",
  "xch_00093": "Customer Name",
  "xch_00094": "Error Message",
  "xch_00095": "Pay",
  "xch_00096": "{0} transfers are ready to pay",
  "xch_00097": "Congratulations, {0} transfers have been created successfully. You can proceed to complete the payment.",
  "xch_00098": "Payment overview",
  "xch_00099": "Source currency",
  "xch_00100": "Target currency",
  "xch_00101": "Target Amount to transfer",
  "xch_00102": "Exchange rates",
  "xch_00103": "Source Amount to convert",
  "xch_00104": "Total Fees",
  "xch_00105": "Total {0} amount to pay",
  "xch_00106": "Balance before payment",
  "xch_00107": "Balance after payment",
  "xch_00108": "{0} transfer(s) successful",
  "xch_00109": "{0} transfer(s) failed",
  "xch_00110": "Re-batch",
  "xch_00111": "Continue without these transfer(s)",
  "xch_00112": "Confirm",
  "xch_00113": "Confirm Payment",
  "xch_00114": "After clicking ‘confirm’, the batch transfer will start processing.",
  "xch_00115": "Your balance is insufficient",
  "xch_00116": "Please deposit funds to your wallet first",
  "xch_00117": "Your batch transfers is being processing",
  "xch_00118": "This usually takes some time, please be patient.",
  "xch_00119": "The customer's identity document expires, please get in touch with the customer to supplement the validity period of the new identity document.",
  "xch_00120": "The payer's nationality does not match the issue country of the identification document.",
  "xch_00121": "The identification document's issue country must be the payer's residence country.",
  "xch_00122": "The customer's age does not meet the risk control requirements.",
  "xch_00123": "The payer cannot be a company.",
  "xch_00124": "The payer hits the blacklist and the transaction is not allowed.",
  "xch_00125": "The payee hits the blacklist and the transaction is not allowed. Suggest changing to another payee.",
  "xch_00126": "The payee's bank card information verification failed. It may be that the customer's bank card information was filled incorrectly. Please get in touch with the customer to confirm the bank card information (name, card number, ID number, etc.) or change to another bank card or payee and try again.",
  "xch_00127": "The payee's bank card does not support collecting money, please get in touch with the customer to change to another bank card or payee and try again.",
  "xch_00128": "The payee's transfer quota exceeds the limit. It is recommended that the customer try again after a period of time.",
  "xch_00129": "The payee's bank card status is abnormal and does not support receiving money. Please get in touch with the customer to change a bank card or payee and try again.",
  "xch_00130": "You can only upload files filled with transfer data based on the template.",
  "xch_00131": "Only one file can be uploaded in the same batch",
  "xch_00132": "The version of the uploaded template file is too old, please re-download and fill in the transfer data and upload again.",
  "xch_00133": "The batch transfers has completed, but the following transfers have failed. You can check the failure reasons listed below.",
  "xch_00134": "Congratulations, the batch transfer has been completed.",
  "xch_00135": "You have a batch transfer pending payment, please continue to complete the payment or cancel it.",
  "xch_00136": "You have a batch transfer under the transfer processing state, please wait patiently for the batch transfer to complete before uploading a new batch.",
  "xch_00137": "Developer",
  "xch_00138": "xTransfer Payout API",
  "xch_00139": "Expand {0} with one click {1}",
  "xch_00140": "your business to China",
  "xch_00141": "7×24 hrs Instant Payout ",
  "xch_00142": "market-leading Rate",
  "xch_00143": "support almost All Banks in China",
  "xch_00144": "Start integration",
  "xch_00145": "Search",
  "xch_00146": "Start date",
  "xch_00147": "End date",
  "xch_00148": "Order or Name",
  "xch_00149": "Reset",
  "xch_00150": "Export",
  "xch_00151": "Payee Name",
  "xch_00152": "Currency Pair",
  "xch_00153": "Source Amount",
  "xch_00154": "Fee",
  "xch_00155": "Rate",
  "xch_00156": "Sorry, the number of your transfers exceeds the maximum of {0} we support. Please delete some transfer(s) and upload again.",
  "xch_00157": "Sorry, we did not read any transfer data in the file you uploaded. Please make sure your uploaded file is correct and upload it again.",
  "xch_00158": "Target Amount",
  "xch_00159": "Pending",
  "xch_00160": "Completed",
  "xch_00161": "Failed",
  "xch_00162": "Cancelled",
  "xch_00163": "Detail",
  "xch_00164": "Keywords",
  "xch_00165": "Total {0} items",
  "xch_00166": "We updated a new template on {0}, please download the new template first, then fill in the transfer data and upload it, so that we can correctly parse your batch transfer data file.",
  "xch_00167": "Transaction Detail",
  "xch_00168": "Template Updated",
  "xch_00169": "Total Source Amount",
  "xch_00170": "Exchange rate",
  "xch_00171": "Payer info",
  "xch_00172": "Basic info",
  "xch_00173": "ID info",
  "xch_00174": "Type",
  "xch_00175": "Issue Country",
  "xch_00176": "Number",
  "xch_00177": "Expire Date",
  "xch_00178": "Address",
  "xch_00179": "Addressline1",
  "xch_00180": "City",
  "xch_00181": "District or State",
  "xch_00182": "Post Code",
  "xch_00183": "Resident Country",
  "xch_00184": "Association",
  "xch_00185": "Source of Funds",
  "xch_00186": "Transfer Purpose",
  "xch_00187": "Payee info",
  "xch_00188": "Bank Account No",
  "xch_00189": "Identification Card",
  "xch_00190": "Country",
  "xch_00191": "State / Province",
  "xch_00192": "Prefecture of District",
  "xch_00193": "Nationality",
  "xch_00194": "Occupation",
  "xch_00195": "Validity Period",
  "xch_00196": "The ID card information reserved by the payee in the bank has expired, and the funds cannot be collected. Suggest getting in touch with the customer to update the ID card information or change to another bank card or payee and try again.",
  "xch_00197": "The format of the payee's ID card number is incorrect. Please get in touch with the customer to confirm the ID number information or change to another payee and try again.",
  "xch_00198": "The payee's bank card is a class II or III personal bank settlement account, which exceeds the daily limit. Please get in touch with the customer to try again tomorrow or change to another bank card or payee and then try again. Note: The daily limit for class II account is usually 10,000, and for class III account is 5,000)",
  "xch_00199": "Failed to settle foreign exchange, in cross-currency transaction mode, the settlement time is Monday to Friday 9:30-16:20, UTC+08:00 on weekdays.",
  "xch_00200": "The bank reported that the format of your transfer information was incorrect, and the transfer failed. Please confirm this transfer information or contact us for help.",
  "xch_00201": "The transfer is abnormal, please contact us for help.",
  "xch_00202": "The bank reported that the payee's birthday format for the transfer was incorrect. Please confirm this transfer information or contact us for help.",
  "xch_00203": "The bank reported that the name of the payee of this transfer did not match. Please get in touch with the customer to confirm the information or change to another payee and try again.",
  "xch_00204": "The bank reported that the payee of the transfer failed with the online verification and could not collect the money, please get in touch with the customer to confirm the information or change to another payee and try again.",
  "xch_00205": "With insufficient balance, the transfer failed. Please confirm the balance in your wallet or contact us for help.",
  "xch_00206": "The bank reported that the payer's bank account for this transfer can not be empty. Please confirm this transfer information or contact us for help.",
  "xch_00207": "The bank reported that the payer's residence country for this transfer can not be empty. Please confirm this transfer information or contact us for help.",
  "xch_00208": "Temporarily unable to pay",
  "xch_00209": "Payment failed due to failure to perform batch transfer in cross-currency transaction mode (transfer availability time is Monday to Friday 9:30-16:00, UTC+08:00 on working days). Please try to perform a payment to this batch transfer within the settlement time or use the same currency transaction mode (transfer availability time is 7x24 hr). If you have any questions, please contact us.",
  "xch_00210": "The {0} for this transfer can not be empty. Please confirm this transfer information or contact us for help.",
  "xch_00211": "The payee's bank card number is not a UnionPay card number, so the transfer is not supported. Please get in touch with the customer to change to UnionPay card number or another payee and try again. Note: Currently, bank accounts (such as passbook accounts) are not supported.",
  "xch_00212": "The merchant order number of this transfer already exists in the historical transfers, please confirm whether the information is correct.",
  "xch_00213": "One of \"Source Amount\" and \"Target Amount\" is required",
  "xch_00214": "The prefecture or district of the payee's address is not recognized or filled in incorrectly.",
  "xch_00215": "Display Settings",
  "xch_00216": "Day",
  "xch_00217": "Week",
  "xch_00218": "Month",
  "xch_00219": "The payer or payee's account needs further manual review, usually we review it quickly, if this status persists, please contact us for help.",
  "xch_00220": "The mobile phone number of the payer or payee has been bound by someone else, please contact us for help.",
  "xch_00221": "The payer or payee's information is duplicated with others and needs further manual review. Usually we review it qucikly. If this status persists, please contact us for help.",
  "xch_00222": "The option value you filled in for \"{0}\" is incorrect. Please confirm that you are filling in according to the option requirements. If you have any questions, you can check the help document or contact us for help.",
  "xch_00223": "All transfers are failed to process",
  "xch_00224": "You can modify and re-upload. ",
  "xch_00225": "We will lock the exchange rate for you only after you click \"Confirm Payment\" to complete the payment. ",
  "xch_00226": "The format of \"{0}\" you filled is incorrect. Please confirm that you have filled it according to the date format requirements. If you have any questions, you can check the help documentation or contact us for help.",
  "xch_00227": "The format of \"{0}\" you filled in is incorrect. Please make sure that the information you fill in does not contain characters other than numbers. If you have any questions, you can check the help file or contact us for help.",
  "xch_00228": "The currency of \"{0}\" you filled in is not supported at the moment. If you have any questions, please contact us for help.",
  "xch_00229": "The amount of \"{0}\" you filled exceeds the maximum limit. If you have any questions, please contact us for help.",
  "xch_00230": "In this template, the payee’s name must be in Chinese. If you have any questions, you can check the help documentation or contact us for help.",
  "xch_00231": "Account Balance",
  "xch_00232": "Account Detail",
  "xch_00233": "Account",
  "xch_00234": "Company",
  "xch_00235": "TRAVEL TAO LIMITED",
  "xch_00236": "Refresh",
  "xch_00237": "Reference only, valid for 5 mins. Actual rate upon confirmation. ",
  "xch_00238": "Update for workday on 6:30 pm. ",
  "xch_00239": "day ago",
  "xch_00240": "days ago",
  "xch_00241": "hour ago",
  "xch_00242": "hours ago",
  "xch_00243": "minute ago",
  "xch_00244": "minutes ago",
  "xch_00245": "Total Amount",
  "xch_00246": "All transfer(s) failed",
  "xch_00247": "You can check the failure reasons listed below. ",
  "xch_00248": "Export {0} items",
  "xch_00249": "Export {0} item",
  "xch_00250": "Template",
  "xch_00251": "Custom columns",
  "xch_00252": "Available Column",
  "xch_00253": "Display Column",
  "xch_00254": "Payment completion time",
  "xch_00255": "The upload failed due to network fluctuations, please upload again.",
  "xch_00256": "Log out",
  "xch_00257": "Authorization failed. Please re-login. ",
  "xch_00258": "Re-Login",
  "xch_00259": "All columns",
  "xch_00260": "View Details",
  "xch_00261": "The payer's identification document cannot be the China Resident Identity Cards.",
  "xch_00262": "Deposit to Balance",
  "xch_00263": "Confirm Amount",
  "xch_00264": "Track",
  "xch_00265": "Fill in Deposit Info",
  "xch_00266": "Deposit Amount",
  "xch_00267": "Limitation",
  "xch_00268": "Deposit Type",
  "xch_00269": "Support “Low Cost Exchange”",
  "xch_00270": "Support “Quick Exchange”",
  "xch_00271": "FX Rate: ",
  "xch_00272": "Delivery Time Limit: ",
  "xch_00273": "FX Trade Availability Time: ",
  "xch_00274": "Better exchange rate",
  "xch_00275": "Instant delivery",
  "xch_00276": "Working day",
  "xch_00277": "Please use wire transfer to deposit to the following account",
  "xch_00278": "Account Information",
  "xch_00279": "Payment Detail",
  "xch_00280": "Currency",
  "xch_00281": "Bank Name",
  "xch_00282": "SWIFT CODE",
  "xch_00283": "Bank Address",
  "xch_00284": "Beneficiary Name",
  "xch_00285": "Account Number",
  "xch_00286": "Beneficiary Address",
  "xch_00287": "Payment reference",
  "xch_00288": "You Deposit",
  "xch_00289": "You Get",
  "xch_00290": "Should Arrive",
  "xch_00291": "in {0} days",
  "xch_00292": "You created a deposit order",
  "xch_00293": "Follow the prompts to complete the deposit",
  "xch_00294": "Your money is being sent to us",
  "xch_00295": "We have received your {0}",
  "xch_00296": "We add your {0} to your balance",
  "xch_00297": "We’ve not paid",
  "xch_00298": "We’ve paid",
  "xch_00299": "Cancel Deposit",
  "xch_00300": "Balance Transaction",
  "xch_00301": "{0} Balance",
  "xch_00302": "Exchange",
  "xch_00303": "Amount",
  "xch_00304": "Exchange Amount",
  "xch_00305": "Available Balance:",
  "xch_00306": "Submit Order",
  "xch_00307": "Select All",
  "xch_00308": "Low Cost Mode",
  "xch_00309": "Quick Mode",
  "xch_00310": "When to Exchange",
  "xch_00311": "Right now",
  "xch_00312": "At desired rate (coming soon)",
  "xch_00313": "At desired time (coming soon)",
  "xch_00314": "Rate trends",
  "xch_00315": "Coming Soon",
  "xch_00316": "Confirm Order",
  "xch_00317": "Exchange Time Overview",
  "xch_00318": "Exchange Mode",
  "xch_00319": "Target Amount to get",
  "xch_00320": "Reference rates",
  "xch_00321": "{0} balance after exchange",
  "xch_00322": "Total {0} amount to exchange",
  "xch_00323": "Exchange Transaction Overview",
  "xch_00324": "Cancel Exchange",
  "xch_00325": "Processing",
  "xch_00326": "Limits {0}~ {1} {2}",
  "xch_00327": "Available",
  "xch_00328": "Total",
  "xch_00329": "Deposit",
  "xch_00330": "Exchange",
  "xch_00331": "The bank and its inter-bank may charge a handling fee during the wire transfer process. It also depends on the handling fee payment method you choose when making a wire transfer. For details, please get in touch with your account opening bank. The deposit amount is subject to the actual amount we received.",
  "xch_00332": "Support low-cost exchange fund",
  "xch_00333": "Support quick exchange fund",
  "xch_00334": "The speed of transfer is related to the operation time of the inter-bank of transfer bank and the selected transfer route. It is recommended that you provide the transfer route info to our customer success manager so that we can provide you with better advice to improve the speed of deposit.",
  "xch_00335": "Success",
  "xch_00336": "Ongoing",
  "xch_00337": "Transaction ID",
  "xch_00338": "Transaction Time",
  "xch_00339": "Amount ({0})",
  "xch_00340": "Search ID",
  "xch_00341": "Filter",
  "xch_00342": "{0} Balance",
  "xch_00343": "Balance detail",
  "xch_00344": "Arrived time",
  "xch_00345": "Open (Need action)",
  "xch_00346": "Onboarding",
  "xch_00347": "Screening",
  "xch_00348": "Confirmed (Under review)",
  "xch_00349": "Cleared",
  "xch_00350": "Confirmed (Risk mitigated)",
  "xch_00351": "Confirmed (No risk)",
  "xch_00352": "Confirmed (Blocked)",
  "xch_00353": "Self",
  "xch_00354": "Parent",
  "xch_00355": "Spouse",
  "xch_00356": "Children",
  "xch_00357": "Sibling",
  "xch_00358": "Grandparent",
  "xch_00359": "Grandchildren",
  "xch_00360": "Name, Phone Number, ID Number",
  "xch_00361": "Case Detail",
  "xch_00362": "Male",
  "xch_00363": "Related Alerts",
  "xch_00364": "Association Info",
  "xch_00365": "Upload",
  "xch_00366": "History",
  "xch_00367": "Last Name",
  "xch_00368": "First Name",
  "xch_00369": "Last Name (Pinyin)",
  "xch_00370": "First Name (Pinyin)",
  "xch_00371": "Streets",
  "xch_00372": "Detail Address",
  "xch_00373": "Postcode",
  "xch_00374": "ID Number",
  "xch_00375": "Confirm exchange? Your order will be exchange right now.",
  "xch_00376": "The exchange rate has changed",
  "xch_00377": "Because of the international foreign exchange market fluctuations in real-time, the exchange rate has changed. Please reconfirm the exchange rate quotation.",
  "xch_00378": "Expiration Date",
  "xch_00379": "Issuing Date",
  "xch_00380": "View Certificate",
  "xch_00381": "You created an exchange order",
  "xch_00382": "Wait for the exchange condition to meet",
  "xch_00383": "We have exchanged your {0}",
  "xch_00384": "We add your {0} to your balance",
  "xch_00385": "Transation Rate",
  "xch_00386": "Related Transactions",
  "xch_00387": "We have fully upgraded to the same-currency (CNY) transaction mode, which supports 7×24 hour instant transfer. Please exchange the foreign currency to the CNY wallet before batch transfer. If you have any questions, you can contact our customer success manager.",
  "xch_00388": "Got it",
  "xch_00389": "Balance Transactions",
  "xch_00390": "Transfer Transactions",
  "xch_00391": "Notifications",
  "xch_00392": "Cancel this {0} order?",
  "xch_00393": "This {0} order will not be processed, you can initiate it again later.",
  "xch_00394": "Confirm cancel",
  "xch_00395": "No",
  "xch_00396": "We receive your {0}",
  "xch_00397": "Please transfer {0} to the bank detail we provide to complete the deposit.",
  "xch_00398": "Your bank might take up a little time to get it to us. We'll let you know when it arrives.",
  "xch_00399": "We are adding your {0} to your balance",
  "xch_00400": "We have added your {0} to your balance",
  "xch_00401": "You cancelled the order",
  "xch_00402": "You completed the deposit",
  "xch_00403": "Transaction rate",
  "xch_00404": "Exchange is not available at the moment",
  "xch_00405": "Exchange is available on {0}, please try again at this time.",
  "xch_00406": "Cancel Order",
  "xch_00407": "No",
  "xch_00408": "{0} is required",
  "xch_00409": "{0} balance after deposit",
  "xch_00410": "End of list",
  "xch_00411": "Instant",
  "xch_00412": "Notifications List",
  "xch_00413": "We are exchanging your {0}",
  "xch_00414": "New",
  "xch_00415": "You have already added this rate",
  "xch_00416": "Please Select",
  "xch_00417": "Low Cost Mode",
  "xch_00418": "You created a batch transfer order",
  "xch_00419": "Your transfer order is waiting to be paid",
  "xch_00420": "Your have paid your transfer order",
  "xch_00421": "The batch transfers have been completed",
  "xch_00422": "Your batch transfers are processing",
  "xch_00423": "All transfers failed to process",
  "xch_00424": "We Process your batch transfer",
  "xch_00425": "Waiting for the exchange conditions to be met",
  "xch_00426": "The exchange conditions have been met",
  "xch_00427": "According to domestic banking and regulatory requirements, you need to provide the payer's certificate and proof of kinship in the background, if you have any questions, you can contact our Customer Manager.",
  "xch_00428": "According to domestic banking and regulatory requirements, you need to provide the payer's ID and proof of income in the background, if you have any questions, you can contact our Customer Manager.",
  "xch_00429": "Rejected",
  "xch_00430": "Change Password",
  "xch_00431": "The beneficiary's bank card exceeds the daily limit. Please contact the customer and try again tomorrow, or try another bank card or beneficiary.",
  "xch_00432": "The identity verification of the payer or payee failed. Please confirm whether the identity information or proof materials you submitted are correct. If you have any questions, you can contact our Customer Manager.",
  "xch_00433": "Awaiting Transfer",
  "xch_00434": "Pending",
  "xch_00435": "Pending Material",
  "xch_00436": "Confirm transfer? Your order will be transfer right now.",
  "xch_00437": "Transaction Materials",
  "xch_00438": "According to the anti-money laundering requirements of cooperative banks, in order to ensure the continuous use of our servicesPlease submit the proof of the relationship between the payee",
  "xch_00439": "and the payer",
  "xch_00440": "within 48 hours.",
  "xch_00441": "Relationship Proof",
  "xch_00442": "The payee is the payer's",
  "xch_00443": "Append Material",
  "xch_00444": "Please select the certificate type to be authenticated.",
  "xch_00445": "Payer's certificate",
  "xch_00446": "Marriage Certificate",
  "xch_00447": "Kinship Commitment Letter",
  "xch_00448": "Household Register Book",
  "xch_00449": "Maintenance Notary Certificate",
  "xch_00450": "Birth Certificate",
  "xch_00451": "Employment Contract",
  "xch_00452": "Income Statement",
  "xch_00453": "Incumbency Certification",
  "xch_00454": "Salary/wage Statement",
  "xch_00455": "Tax Certificate",
  "xch_00456": "Sibling",
  "xch_00457": "Grandchildren",
  "xch_00458": "Risk Control",
  "xch_00459": "Support Docs",
  "xch_00460": "failed",
  "xch_00461": "Merchant order or Certificate number or  Name",
  "xch_00462": "Payer",
  "xch_00463": "Payer's certificate number",
  "xch_00464": "Payee",
  "xch_00465": "Please Upload",
  "xch_00466": "Template",
  "xch_00467": "Examples are as follows",
  "xch_00468": "The payment cannot be transfered, the payment status is not awaiting_transfer",
  "xch_00469": "Transfer error",
  "xch_00470": "Payee's certificate number",
  "xch_00471": "Material review in progress",
  "xch_00472": "The relationship cannot be other people. If you need supplementary materials, please contact the account manager.",
  "xch_00473": "Online signature",
  "xch_00474": "Please sign before submitting.",
  "xch_00475": "Online signing",
  "xch_00476": "Replenish",
  "xch_00477": "Waiting",
  "xch_00478": "The link is invalid, please contact customer service to get it again.",
  "xch_00479": "Refresh Kinship Commitment Letter",
  "xch_00480": "Refresh",
  "xch_00481": "XC Mode",
  "xch_00482": "Welcome to use",
  "xch_00483": "Layout",
  "xch_00484": "Balance Entry",
  "xch_00485": "Search Support Docs",
  "xch_00486": "Merchant Order Detail",
  "xch_00487": "Support “XC Exchange”",
  "xch_00488": "Customer Manage",
  "xch_00489": "Customer List",
  "xch_00490": "Rate List",
  "xch_00491": "Order Manage",
  "xch_00492": "Deposit Order",
  "xch_00493": "Exchange Order",
  "xch_00494": "Funds Manage",
  "xch_00495": "Funds Overview",
  "xch_00496": "Customer Balance",
  "xch_00497": "Customer List Entry",
  "xch_00498": "Balance Detail",
  "xch_00499": "Support XC Exchange",
  "xch_00500": "Select Mode",
  "xch_00501": "Deposit XCNH",
  "xch_00502": "Balance Manage",
  "xch_00503": "Wallet Manage",
  "xch_00504": "Order Detail",
  "xch_00505": "Choose Template",
  "xch_00506": "Upload your file, or choose a template to download and then get started.",
  "xch_00507": "Choose Template",
  "xch_00508": "Choose a template to download",
  "xch_00509": "We support different file templates to help you start batch transfer. Use the one that works best for you.",
  "xch_00510": "If you know your payee’s bank details, you can transfer money directly to their bank accounts.",
  "xch_00511": "Collection information of payees：",
  "xch_00512": "Payee Full Name (Chinese)",
  "xch_00513": "Payee Gender",
  "xch_00514": "Payee Nationality",
  "xch_00515": "Payee Occupation",
  "xch_00516": "Payee Postcode ",
  "xch_00517": "Payee Province",
  "xch_00518": "Payee City ",
  "xch_00519": "Payee Prefecture or District",
  "xch_00520": "Payee Addressline1 ",
  "xch_00521": "Payee Phone Number",
  "xch_00522": "Payee ID Card No",
  "xch_00523": "Chinese ID Start Date",
  "xch_00524": "Chinese ID Expire Date",
  "xch_00525": "Payee Bank Card No",
  "xch_00526": "If you just know your payee’s bank little details, you can transfer money directly to their bank accounts.",
  "xch_00527": "Payee Full Name (Chinese)",
  "xch_00528": "Download",
  "xch_00529": "To Transfer",
  "xch_00531": "Fee: {0}  / Per Transfer",
  "xch_00533": "Switching Mode",
  "xch_00534": "Choose Mode",
  "xch_00535": "Classic Mode",
  "xch_00536": "Xpress Mode",
  "xch_00537": "Direct to bank card",
  "xch_00538": "Network error, please refresh later and try again.",
  "xch_00539": "Support classic mode batch",
  "xch_00540": "Support xpress mode batch",
  "xch_00541": "Back to Balance Page",
  "xch_00542": "Transfer Mode",
  "xch_00543": "Created",
  "xch_00544": "Pending",
  "xch_00545": "Completed",
  "xch_00546": "Received",
  "xch_00547": "Cancel",
  "xch_00548": "Exchanged",
  "xch_00549": "Total pool of customer funds",
  "xch_00550": "The system reserve fund is insufficient, it is being prepared, please wait",
  "xch_00551": "The transaction is faster, but the exchange rate is not optimal.",
  "xch_00552": "The exchange rate is the best, but the transaction is not the fastest.",
  "xch_00553": "The fastest transaction also has a better exchange rate.",
  "xch_00554": "Notification Control",
  "xch_00555": "Notification List",
  "xch_00556": "Analysis Control",
  "xch_00557": "Currency Analysis",
  "xch_00558": "Trabsaction Analysis",
  "xch_00559": "Remittance situation",
  "xch_00560": "Consume Time",
  "xch_00561": "Welcome",
  "xch_00562": "Customer Balance",
  "xch_00563": "Interpretation of trading mode",
  "xch_00564": "Customer Name",
  "xch_00565": "Deposit Currency",
  "xch_00566": "Transfer Currency",
  "xch_00567": "Non-Existent Rate",
  "xch_00568": "Email",
  "xch_00569": "Account Status",
  "xch_00570": "Operate",
  "xch_00571": "Update",
  "xch_00572": "Remittance Record",
  "xch_00573": "Please input customer name",
  "xch_00574": "Please Input {0}",
  "xch_00575": "Customer Number",
  "xch_00576": "Customer Code",
  "xch_00577": "Please Select {0}",
  "xch_00578": "Remark",
  "xch_00579": "Rate of Progress",
  "xch_00580": "Probably need {0} seconds",
  "xch_00581": "Success",
  "xch_00582": "fail",
  "xch_00583": "Operate",
  "xch_00584": "Remittance Record",
  "xch_00585": "Source Currency",
  "xch_00586": "Target Currency",
  "xch_00587": "Benchmark Exchange Rate Source",
  "xch_00588": "Quotation Exchange Rate",
  "xch_00589": "Target Currency Exchange Amount",
  "xch_00590": "Exchange Difference",
  "xch_00591": "Benchmark Exchange Rate",
  "xch_00592": "Enabled State",
  "xch_00593": "Create",
  "xch_00594": "Exchange Rate Quotation",
  "xch_00595": "Name",
  "xch_00596": "Exchange Difference%",
  "xch_00598": "Currency",
  "xch_00599": "Customer",
  "xch_00601": "Order Quantity",
  "xch_00602": "Trading Volume",
  "xch_00603": "Order Quantity and Trading Volume",
  "xch_00604": "day",
  "xch_00605": "week",
  "xch_00606": "month",
  "xch_00607": "year",
  "xch_00608": "Transaction Aggregation Analysis",
  "xch_00609": "Total Transaction Volume",
  "xch_00610": "Date",
  "xch_00611": "Number of Orders",
  "xch_00612": "Order Amount",
  "xch_00613": "Amount Recorded",
  "xch_00614": "Transfer Certificate",
  "xch_00615": "Receiving Amount",
  "xch_00616": "Actual Recorded Amount",
  "xch_00617": "How to allocation",
  "xch_00618": "Completed",
  "xch_00619": "Allocating",
  "xch_00620": "Support classic mode batch",
  "xch_00621": "Support xpress mode batch",
  "xch_00622": "Workbench",
  "xch_00623": "Transferring",
  "xch_00624": "Your agent has not configured the exchange rate for this currency pair, please contact your agent.",
  "xch_00625": "You have  order that has not been processed, please process it in time!",
  "xch_00626": "Apply for XC",
  "xch_00627": "Total Order Number",
  "xch_00628": "Transfer Finish Number",
  "xch_00629": "Progess",
  "xch_00630": "Batch Transaction List",
  "xch_00631": "Close",
  "xch_00632": "Batch No",
  "xch_00633": "During system maintenance, it shows that autonomous transactions cannot be completed. Please contact your account manager for exchange transactions",
  "xch_00634": "Deselect",
  "xch_00635": "Batch Cancel",
  "xch_00636": "Are you sure you want to cancel the selected order?",
  "xch_00637": "Don't show again",
  "xch_00638": "Transfer Completed",
  "xch_00639": "Add Filtered View",
  "xch_00640": "Edit Filtered View",
  "xch_00641": "Delete Filtered View",
  "xch_00642": "Add Customer",
  "xch_00643": "Customer ID",
  "xch_00644": "Business Type",
  "xch_00645": "Increase Quota",
  "xch_00646": "Check",
  "xch_00647": "lastNameEn",
  "xch_00648": "Last Name (Pinyin)",
  "xch_00649": "First Name",
  "xch_00650": "First Name (Pinyin)",
  "xch_00651": "DOB",
  "xch_00652": "IdNumber",
  "xch_00653": "Expiration Date",
  "xch_00654": "Female",
  "xch_00655": "back",
  "xch_00656": "front",
  "xch_00657": "side",
  "xch_00658": "mitigated",
  "xch_00659": "processing",
  "xch_00660": "passed",
  "xch_00661": "rejected",
  "xch_00662": "Rejected Type",
  "xch_00663": "Reason",
  "xch_00664": "View Name",
  "xch_00665": "This field is required",
  "xch_00666": "Business area",
  "xch_00667": "Delete the tab",
  "xch_00668": "Are you sure to delete this tab?",
  "xch_00669": "PreView",
  "xch_00670": "passed",
  "xch_00671": "processing",
  "xch_00672": "Fund Proof Materials",
  "xch_00673": "Relationship Proof Materials",
  "xch_00674": "Select the supporting materials to submit",
  "xch_00675": "It should contain basic employment information such as the employee's name, employer,income, and duration of employment, as well as the employer's name seal",
  "xch_00676": "It should contain basic employment information such as the employee's name, employer, income, and duration of employment",
  "xch_00677": "Upload the Proof of income provided by the company",
  "xch_00678": "On-the-job proof provided by the company",
  "xch_00679": "Upload the On-the-job proof provided by the company",
  "xch_00680": "It should contain basic employment information such as employee's name, employer and term of employment",
  "xch_00681": "It should contain the employer or a postscript on the payment of wages, and the employee's name",
  "xch_00682": "Recent wage income bank statement",
  "xch_00683": "Upload the Recent wage income bank statement",
  "xch_00684": "It should contain basic information such as employee's name and total taxable income for the past year",
  "xch_00685": "Certificate of tax payment",
  "xch_00686": "Upload the Certificate of tax payment",
  "xch_00687": "Please provide a letter of commitment of kinship with the recipient to ensure continued use of our services.",
  "xch_00688": "Please provide your household registration book information to ensure continuous use of our services.",
  "xch_00689": "Please provide the maintenance notarization document as proof to ensure continuous use of our services.",
  "xch_00690": "Please provide your marriage certificate information to ensure continued use of our services.",
  "xch_00691": "Please provide the birth certificate of the relative receiving payment to ensure continued use of our services.",
  "xch_00692": "Back",
  "xch_00693": "Please check whether it is filled in completely",
  "xch_00694": "Additional Information",
  "xch_00695": "Failed to obtain profile filter view data",
  "xch_00696": "Deletion failed, no such record, please refresh",
  "xch_00697": "Repetitive name, please resubmit with a different name",
  "xch_00698": "Saving filter view failed",
  "xch_00699": "BankCard Id",
  "xch_00700": "Dow Jones Verification",
  "xch_00701": "Agree and continue ",
  "xch_00702": "Your personal information will be encrypted and stored ",
  "xch_00703": "Quick ID authentication",
  "xch_00704": "And Increase Quota",
  "xch_00705": "We'd be happy to help your clients increase their transfer limits. We need further materials so your client can send more money home",
  "xch_00706": "Upload and identify {0} as proof of identity",
  "xch_00707": "ID card or passport",
  "xch_00708": "Supplement personal information such as {0}",
  "xch_00709": "Occupation And Residential Address",
  "xch_00710": "FullName",
  "xch_00711": "Proof of identity details",
  "xch_00712": "Collection date",
  "xch_00713": "Collect materials",
  "xch_00714": "Awaiting Transfer",
  "xch_00715": "Batch Transaction List",
  "xch_00716": "My Customer",
  "xch_00717": "Cases Management",
  "xch_00718": "Cases Detail",
  "xch_00719": "Add Customer",
  "xch_00720": "Support \"Business Payout Exchange\"",
  "xch_00721": "Trade Mode",
  "xch_00722": "Support Business Payout Transfers",
  "xch_00723": "Transaction Scenario",
  "xch_00724": "Personal Payout ",
  "xch_00725": "Business Payout ",
  "xch_00726": "Corporate Remittance",
  "xch_00727": " Coming Soon",
  "xch_00728": "Personal Payout transfer",
  "xch_00729": "Business Payout transfer",
  "xch_00730": "Personal Payout Transactions",
  "xch_00731": "Business Payout Transactions",
  "xch_00732": "Payout Business Name",
  "xch_00733": "Nature of payment",
  "xch_00734": "Payee Business Name",
  "xch_00735": "Actual Receiving Account Name",
  "xch_00736": "Append Material",
  "xch_00737": "It should include the names and addresses of both parties to the transaction, the name, material, quantity, model, unit price, total price, transaction date, signatures or seals of both parties to the transaction, transaction order number, invoice number and other information.",
  "xch_00738": "PI/PO",
  "xch_00739": "Upload proforma invoice/purchase order",
  "xch_00740": "Order No",
  "xch_00741": "Order Proof Materials",
  "xch_00742": "Upload File",
  "xch_00743": "Confirm Information",
  "xch_00744": "Create Order",
  "xch_00745": "Ready to create {0} orders",
  "xch_00746": "Congratulations, all orders have been successfully verified. You can continue to create your order.",
  "xch_00747": "Congratulations, {0} orders have been successfully verified. You can continue to create your order.",
  "xch_00748": "After clicking \"Confirm\", the order will be created.",
  "xch_00749": "Confirm Order Creation",
  "xch_00750": "All orders were created successfully",
  "xch_00751": "Congratulations, all orders have been successfully created and you can proceed to complete payment.",
  "xch_00752": "Go to Supplementary Materials",
  "xch_00753": "Upload Overview",
  "xch_00754": "Successfully verified orders",
  "xch_00755": "Orders that failed to verify",
  "xch_00756": "Transaction List",
  "xch_00757": "Transaction Information",
  "xch_00758": "Logistics Number",
  "xch_00759": "Logistics company",
  "xch_00760": "Material Information",
  "xch_00761": "Transaction Contract",
  "xch_00762": "Business Chinese Name",
  "xch_00763": "Collection Account Name",
  "xch_00764": "Receiving Bank Account",
  "xch_00765": "Account opening bank",
  "xch_00766": "Business Name",
  "xch_00767": "Business Payment Account",
  "xch_00768": "Upload up to 8 files",
  "xch_00769": "DepositPayment",
  "xch_00770": "FullPayment",
  "xch_00771": "FinalPayment",
  "xch_00772": "Other",
  "xch_00773": "The 「Payee Bank Name」 you filled in is empty or irregular, please provide the details of the receiving bank and resubmit the order.",
  "xch_00774": "The「Payee Bank Account Name（Chinese）」you filled is different from the company name. When selecting a company for payment, the account name must be consistent with the company name.",
  "xch_00775": "Compliant Review",
  "xch_00776": "Pending Transfer",
  "xch_00777": "Beneficiary Information",
  "xch_00778": "Payer Information",
  "xch_00779": "Overrule",
  "xch_00780": "Please submit materials before uploading",
  "xch_00781": "Support Personal Payout transfer",
  "xch_00782": "Order Search",
  "xch_00783": "Payout Channel Code",
  "xch_00784": "Smart Routing Details",
  "xch_00785": "Timestamp",
  "xch_00786": "Event",
  "xch_00787": "Note",
  "xch_00788": "{0} payout successful",
  "xch_00789": "Received payment request ",
  "xch_00790": "Attempted payout via {0}",
  "xch_00791": "Requested payout through {0}, awaiting response.",
  "xch_00792": "{0} payout failed ",
  "xch_00793": "Following routing rules, {0} was selected as the preferred channel.",
  "xch_00794": "History Stream",
  "xch_00795": "On-chain History Stream",
  "xch_00796": "Capital flow information flow binding",
  "xch_00797": "On-chain Record",
  "xch_00798": "Delayed Payout",
  "xch_00799": "Due to high traffic in the payment channel, your payout request will be slightly delayed. We will process the payout as soon as possible. Please be patient. If you have any questions, please contact the customer manager.",
  "xch_00800": "According to the anti-money laundering requirements of the cooperative bank, in order to ensure the continuous use of our services, please submit the image of the ID card of the payee {0} within 48 hours.",
  "xch_00801": "Upload Resident ID Card of PRC",
  "xch_00802": "Profile page ",
  "xch_00803": "National emblem side ",
  "xch_00804": "Attention: please do not use card case or wite-out to cover the documents",
  "xch_00805": "Wrong direction",
  "xch_00806": "Incomplete Shooting",
  "xch_00807": "Reflecting strong",
  "xch_00808": "Ambiguous",
  "xch_00809": "According to the regulatory requirements of the bank, this order needs manual review by the bank, please wait for a moment, if you have any questions, please contact your account manager.",
  "xch_00810": "Proof of identity",
  "xch_00811": "Proof of transaction",
  "xch_00812": "Click to the supplementary material",
  "xch_00813": "Due to changes in policies, relevant orders need to be fulfilled with identity data to complete.",
  "xch_00814": "According to the domestic bank and regulatory requirements, you need to provide transaction proof in the background to ensure the safety of your remittance.",
  "xch_00815": "According to domestic banks and regulatory requirements, you need to provide the front and back of the latest payee ID card image in the background. If you have any questions, please contact our account manager.",
  "xch_00816": "Upload proof of transaction",
  "xch_00817": "Sign the NDA",
  "xch_00818": "Sign immediately",
  "xch_00819": "Submit company materials",
  "xch_00820": "Go to Submit",
  "xch_00821": "Complete the questionnaire",
  "xch_00822": "Go to fill in",
  "xch_00823": "Failure to get information",
  "xch_00824": "👏🏻 Welcome, {0}",
  "xch_00825": "There are still {0} steps left to unlock all features",
  "xch_00826": "There are also the following {0} steps to complete:",
  "xch_00827": "Company Management",
  "xch_00828": "Company Information",
  "xch_00829": "Add Company",
  "xch_00830": "Jurisdiction of Incorporation ",
  "xch_00831": "Name of company",
  "xch_00832": "Company Website",
  "xch_00833": "Company Type",
  "xch_00834": "Basic information",
  "xch_00835": "Certificate of Incorporation",
  "xch_00836": "Shareholding Structure",
  "xch_00837": "UBO Information Document",
  "xch_00838": "UBO Identity Document",
  "xch_00839": "Licensing Documentation",
  "xch_00840": "Questionnaire 1",
  "xch_00841": "Questionnaire 2",
  "xch_00842": "Questionnaire 3",
  "xch_00843": "NDA Document",
  "xch_00844": "Previous step",
  "xch_00845": "Next steps",
  "xch_00846": "Fill in Company Info",
  "xch_00847": "Unlock all features of the xCurrency bulk payment platform",
  "xch_00848": "Thank you for choosing us to be your delivery partner in China. In order to unlock the functions of the bulk payment platform more smoothly, please provide some basic information to let us know about you quickly.",
  "xch_00849": "Sign the NDA",
  "xch_00850": "Company Details",
  "xch_00851": "After signing, we will prepare 200 CNY trial fund",
  "xch_00852": "CNY trial funds can be used for distribution within China, and small amount of funds can be issued for verification.",
  "xch_00853": "View Tutorial",
  "xch_00854": "Sign",
  "xch_00855": "To sign",
  "xch_00856": "NDA file preview",
  "xch_00857": "Platform usage tutorial",
  "xch_00858": "Questionnaire",
  "xch_00859": "Through the questionnaire, it is convenient for us to understand you more deeply",
  "xch_00860": "As required by the compliance policy, we need to know all about you through the survey documents",
  "xch_00861": "Fill in online",
  "xch_00862": "Fill out the Board Resolution Account opening questionnaire",
  "xch_00863": "Fill in Due Deligence Form Questionnaire",
  "xch_00864": "Complete the AML/CTF/SCP Questionaire Questionnaire Form",
  "xch_00865": "Filling in offline",
  "xch_00866": "Download the Board Resolution Account opening questionnaire",
  "xch_00867": "Download  Due Deligence Form Questionnaire",
  "xch_00868": "Download the  AML/CTF/SCP Questionaire Form",
  "xch_00869": "Proforma Invoices",
  "xch_00870": "Used to upload Proforma Invoices, including the names and addresses of both parties, the name, material, quantity, model, unit price, total price, transaction date, signatures or stamps of both parties, order number, and invoice number.",
  "xch_00871": "Purchase Orders",
  "xch_00872": "Used to upload Purchase Orders, including the names and addresses of both parties, product name, material, quantity, model, unit price, total price, transaction date, signatures or stamps of both parties, and order number.",
  "xch_00873": "Logistics Materials",
  "xch_00874": "Used to upload logistics materials, including shipping documents, bills of lading, waybills, and other logistics-related documents to ensure tracking and verification of the logistics process.",
  "xch_00875": "Export Declarations",
  "xch_00876": "Used to upload export declarations, including detailed information on exported goods, declaration date, declaration number, and information of both parties involved in the declaration, to meet export compliance requirements.",
  "xch_00877": "Communication Records",
  "xch_00878": "Used to upload communication records between both parties, including emails, chat logs, and phone records, as references and evidence of the transaction process.",
  "xch_00879": "Beneficiary ID Cards",
  "xch_00880": "Used to upload the beneficiary's ID cards, including passports, ID cards, etc., to verify the beneficiary's identity information.",
  "xch_00881": "Beneficiary Power of Attorneys",
  "xch_00882": "Used to upload the beneficiary's power of attorneys, including the signature or stamp of the authorizer, the content of the authorization, etc., to prove the beneficiary's legal authorization.",
  "xch_00883": "Historical Transaction Materials",
  "xch_00884": "Used to upload historical transaction materials between both parties, as references and verification of past transactions.",
  "xch_00885": "Proof of Purchase",
  "xch_00886": "Used to upload proof of purchase from upstream and downstream orders, ensuring the integrity of the supply chain and the authenticity of transactions.",
  "xch_00887": "Other Materials",
  "xch_00888": "Used to upload other related materials to supplement and complete the transaction documents.",
  "xch_00889": "Submit",
  "xch_00890": "Successful submission",
  "xch_00891": "Your materials have not been submitted yet. Are you sure you want to exit?",
  "xch_00892": "Your submission is incomplete. Please ensure either a Proforma Invoice (PI) or a Purchase Order (PO) is submitted.",
  "xch_00893": "Save successfully",
  "xch_00894": "Material review failed, please resubmit.",
  "xch_00895": "Resubmit",
  "xch_00896": "Fill Out Basic Information",
  "xch_00897": "Invitation to Fill Out",
  "xch_00898": "Payment Proof",
  "xch_00899": "Failed to Retrieve Free Quota",
  "xch_00900": "Free Limit",
  "xch_00901": "XC Quota",
  "xch_00902": "Today Detail",
  "xch_00903": "When you have a quota, you can use the XC transfer service for free, if you have any questions, please contact your customer manager.",
  "xch_00904": "This order amount exceeds the limit. If you have any questions, you can contact our customer manager.",
  "xch_00905": "Original Mode",
  "xch_00906": "Support original currency payouts",
  "xch_00907": "Support \"Original Currency Remittance Model\"",
  "xch_00908": "With our Original Currency Remittance Model, you can simplify transactions, avoid uncertainties from fluctuating exchange rates, and ensure you know the exact amount being transferred. Funds are not delayed by conversion procedures, resulting in faster processing times, making it ideal for businesses managing multiple currencies.",
  "xch_00909": "You have pending transfer information. Would you like to proceed?",
  "xch_00910": "Please correct the transfer information.",
  "xch_00911": "Pending Transfer Information: {0}",
  "xch_00912": "Prev",
  "xch_00913": "Next",
  "xch_00914": "Continue Transfer",
  "xch_00915": "Download the corrected file",
  "xch_00916": "Payee Name Format Incorrect",
  "xch_00917": "Payee ID Card Number Format Incorrect",
  "xch_00918": "The payee's phone number cannot be empty",
  "xch_00919": "The payee's phone number format is incorrect",
  "xch_00920": "This information is required",
  "xch_00921": "The amount format is incorrect",
  "xch_00922": "The country code format is incorrect The nationality can be filled in either the two-letter code or the three-letter code, such as SGP ISO 3166-1 alpha-3 or alpha-2 e.g., SGP or SG",
  "xch_00923": "The gender format is incorrect, please ensure it is either Male or Female ",
  "xch_00924": "The date format is incorrect, please ensure it is one of the following: 1.YYYY-MM-DD 2.YYYYMMDD 3.YYYY/MM/DD",
  "xch_00925": "The date format is incorrect, please ensure it is one of the following: 1.YYYY-MM-DD 2.YYYYMMDD 3.YYYY/MM/DD and it cannot be greater than the current date",
  "xch_00926": "The source of funds format is incorrect, please ensure it is one of the following: 1.Employment income 2.Business income 3.Part-time income 4.Saving deposits",
  "xch_00927": "The payer's document type format is incorrect, please ensure it is one of the following: 1.idcard 2.passport 3.driver 4.residence 5.workpermit 6.other",
  "xch_00928": "The relationship between the payer and the payee format is incorrect, please ensure it is one of the following: 1.Self 2.Spouse 3.Parent 4.Children 5.Sibling 6.Grandparent 7.Grandchildren",
  "xch_00929": "The purpose of remittance format is incorrect, please ensure it is one of the following: FamilySupport Salary",
  "xch_00930": "The payee currency format is incorrect, please ensure the currency is CNY",
  "xch_00931": "The payee nationality format is incorrect, please ensure it is one of the following: 1.CHN 2.CN",
  "xch_00932": "The payment currency format is incorrect",
  "xch_00933": "The order number already exists",
  "xch_00934": "Current Progress",
  "xch_00935": "Bank/Digital Bank",
  "xch_00936": "Payment Company",
  "xch_00937": "Remittance Company",
  "xch_00938": "Other Institution（please specify）",
  "xch_00939": "Company Industry (Additional)",
  "xch_00940": "Please carefully read and {0} the relevant information, and {1} your personal information at the end of the document.",
  "xch_00941": "fill in",
  "xch_00942": "Expand Full Text",
  "xch_00943": "Completed, Next Step",
  "xch_00944": "Transferred",
  "xch_00945": "Refunded",
  "xch_00946": "Are you sure you want to cancel the order?",
  "xch_00947": "Are you sure you want to refund?",
  "xch_00948": "Refund",
  "xch_00949": "Business Registration Certificate",
  "xch_00950": "Annual Return of the Company",
  "xch_00951": "Articles of Association",
  "xch_00952": "Office Photos",
  "xch_00953": "AML/CTF Policy",
  "xch_00954": "Risk Assessment Report",
  "xch_00955": "AML/Compliance Officer's Resume",
  "xch_00956": "Board Resolution",
  "xch_00957": "Company Detailed Documents",
  "xch_00958": "Beneficiary Documents",
  "xch_00959": "Compliance Documents",
  "xch_00960": "Processing, expected {0} s",
  "xch_00961": "Document review failed, please resubmit.",
  "xch_00962": "Thank you for choosing the xCurrency Hubs Cross-border Payment System. In accordance with compliance requirements for cooperation between licensed financial institutions, please follow the steps below to provide more information, allowing us to quickly understand your company's basic situation.",
  "xch_00963": "Please enter the company name",
  "xch_00964": "In accordance with financial institution compliance requirements, we need to collect some detailed information about your company to assess its business condition and qualifications, ensuring compliance with financial regulations. All information will be kept strictly confidential and securely stored.",
  "xch_00965": "An error occurred, please try again later",
  "xch_00966": "Submission Successful!",
  "xch_00967": "Please click to jump to the pending content or materials to check and complete. If you encounter any problems during the process, please contact your account manager at any time.",
  "xch_00968": "Welcome Back",
  "xch_00969": "You have {0} steps remaining to complete",
  "xch_00970": "Please navigate to the incomplete steps to continue filling in or uploading",
  "xch_00971": "Continue Filling/Uploading",
  "xch_00972": "We recommend you prepare the corresponding documents according to the following checklist. This will significantly save your time.",
  "xch_00973": "1.Detailed company documents",
  "xch_00974": "Business registration certificate (if available), annual return of the company (if available), articles of association, photos of company office premises",
  "xch_00975": "2.Beneficiary documents of the company",
  "xch_00976": "Equity structure diagram (must be signed by a director or representative)Identity documents of the ultimate beneficial owner (passport photo of ultimate beneficial owner holding more than 25% equity, and valid address proof)Identity information of the actual controller (if there is no ultimate beneficial owner holding more than 25% equity, passport photo and valid address proof of the natural person who enjoys ultimate control of the company)",
  "xch_00977": "3.Compliance-related documents",
  "xch_00978": "License certificate (financial payment license or business permit issued by local regulatory authority)Anti-money laundering / counter-terrorism financing policy (please provide the latest version)Independent third-party risk assessment report (if available, please provide the latest version)Resume of the Anti-Money Laundering Officer / Compliance OfficerBoard resolution for account openingAnti-money laundering / counter-terrorism financing / sanctions questionnaire (to be completed)Due diligence form (to be completed)",
  "xch_00979": "Click to fill in online signatory info",
  "xch_00980": "Other authorized persons can be invited to sign",
  "xch_00981": "Save failed, the content you edited is no longer the latest, please check the content and save again.",
  "xch_00982": "Supplement",
  "xch_00983": "(Please carefully read and {0} the relevant information, and {1} sign your personal information online at the end of the document):",
  "xch_00984": "Save and Submit for Pre-Review",
  "xch_00985": "Required fields are missing",
  "xch_00986": "Must be signed by a director or representative",
  "xch_00987": "Passport photo and valid address proof of the ultimate beneficiary owning more than 25% of the shares",
  "xch_00988": "If there is no ultimate beneficiary owning more than 25% of the shares, provide the passport photo and valid address proof of the individual with ultimate control of the company",
  "xch_00989": "Financial payment license or business permit issued by the local regulatory authority",
  "xch_00990": "Please provide the latest version",
  "xch_00991": "If available, please provide the latest version",
  "xch_00992": "You can use the company's resolution, or {0} for filling and confirmation.",
  "xch_00993": "I am ready, Apply Now",
  "xch_00994": "Download Template",
  "xch_00995": "Welcome.",
  "xch_00996": "Congratulations! You have successfully registered and logged into the xCurrency Hubs Cross-border Payment System. Before we start, in compliance with international requirements for licensed financial institutions, you shall need to complete our customer onboarding procedure in the following steps.",
  "xch_00997": "Each step will clearly indicate where uploads or entries are required.",
  "xch_00998": "Please prepare the following digital documents in formats such as JPG, PDF, DOC, etc.",
  "xch_00999": "Content was auto-saved to the cloud at {0}",
  "xch_01000": "Fill Out Questionnaire",
  "xch_01001": "Your completion rate is below standard. Are you sure you want to submit? A higher completion rate increases approval success!",
  "xch_01002": "Confirm Submission",
  "xch_01003": "If any",
  "xch_01004": "On-site office photos containing the company name or brand name",
  "xch_01005": "Shareholding structure chart (must be signed by a director or representative), identity documents of the ultimate beneficiary (passport photo and valid address proof of the ultimate beneficiary owning more than 25% of the shares), and identity information of the actual controller (if there is no ultimate beneficiary owning more than 25% of the shares, provide the passport photo and valid address proof of the individual with ultimate control of the company)",
  "xch_01006": "Please provide the latest version",
  "xch_01007": "If you hold 25% or more shares, please provide the latest version",
  "xch_01008": "AML/CTF/Sanctions Questionnaire",
  "xch_01009": "Due Diligence Form",
  "xch_01010": "Must be filled in",
  "xch_01011": "We recommend that you prepare the corresponding documents according to the following checklist before starting. This will significantly save your time. If you encounter any problems during the process, please contact your account manager at any time.",
  "xch_01012": "Review Progress",
  "xch_01013": "Review Completed",
  "xch_01014": "Under Review",
  "xch_01015": "Rejected",
  "xch_01016": "Invitation failed, please re-invite or contact your dedicated account manager.",
  "xch_01017": "Please fill in the relevant information and then sign.",
  "xch_01018": "Please enter your name!",
  "xch_01019": "Please enter your email!",
  "xch_01020": "Awaiting Signature",
  "xch_01021": "To Request Signature",
  "xch_01022": "Fill Now",
  "xch_01023": "You have filled and signed",
  "xch_01024": "Awaiting Signature Completion",
  "xch_01025": "Please Fill Signatory Info",
  "xch_01026": "To ensure information security during the cooperation process, protect the legal rights of both parties, and ensure smooth cooperation, both parties need to sign a NDA.",
  "xch_01027": "According to financial institution compliance requirements, we need to understand your company's practices and detailed information regarding business compliance and anti-money laundering/anti-terrorism financing through a questionnaire. This ensures compliance with financial regulations and is a necessary condition for us to provide services to your company. All information will be kept strictly confidential and securely stored.",
  "xch_01028": "Shareholding structure chart (must be signed by a director or representative)",
  "xch_01029": "Ultimate beneficiary identification documents (passport photo and valid address proof of the ultimate beneficiary owning more than 25% of the shares)",
  "xch_01030": "Actual controller identification information (if there is no ultimate beneficiary owning more than 25% of the company's shares, provide the passport photo and valid address proof of the individual with ultimate control of the company)",
  "xch_01031": "License proof (financial payment license or business license issued by local regulatory authorities)",
  "xch_01032": "AML/CTF policy (please provide the latest version)",
  "xch_01033": "Independent third-party risk assessment report (if available, please provide the latest version)",
  "xch_01034": "Resume of the AML officer/compliance officer",
  "xch_01035": "Board resolution for account opening",
  "xch_01036": "AML/CTF/Sanctions Questionnaire (to be filled)",
  "xch_01037": "Due Diligence Form (to be filled)",
  "xch_01038": "Please carefully read and {0} the relevant information. After completing the form, please {1} it for preliminary review. Once the preliminary review is passed, an authorized person will need to sign online.",
  "xch_01039": "Save and Submit for Review",
  "xch_01040": "An error occurred, please refresh and try again.",
  "xch_01041": "Signature failed, please contact your account manager.",
  "xch_01042": "Signature Completed",
  "xch_01043": "Questionnaire attachment",
  "xch_01044": "Upload supporting documents to help us better understand your business (e.g., fund flow of your business)",
  "xch_01045": "Questionnaire pending your completion and submission for preliminary review",
  "xch_01046": "Your questionnaire is under preliminary review",
  "xch_01047": "Preliminary review of the questionnaire completed, awaiting your signature request",
  "xch_01048": "Sign Now",
  "xch_01049": "Top",
  "xch_01050": "Bottom",
  "xch_01051": "Preliminary review not approved, reason: {0}",
  "xch_01052": "Please upload files in image formats (JPEG, PNG, etc.) or PDF format.",
  "xch_01053": "Global Payout",
  "xch_01054": "Order List",
  "xch_01055": "Batch Upload",
  "xch_01056": "Single upload supported. Please do not upload files unrelated to the batch addition of payee information.",
  "xch_01057": "Wrong type of customer",
  "xch_01058": "The email format is wrong",
  "xch_01059": "Please correct the case information",
  "xch_01060": "Continue uploading",
  "xch_01061": "Payee",
  "xch_01062": "Payer",
  "xch_01063": "Individual",
  "xch_01064": "Entity",
  "xch_01065": "Select Customer Type",
  "xch_01066": "The individual profile records basic personal details, contact information, and bank account data, ensuring a safe and accurate remittance process.",
  "xch_01067": "The corporate profile records company details, including basic information, registration, bank accounts, and authorization documents, ensuring legal and compliant remittances.",
  "xch_01068": "Bank Account Number",
  "xch_01069": "Date of Establishment",
  "xch_01070": "Registered Address of the Company",
  "xch_01071": "Company Email",
  "xch_01072": "Phone Number",
  "xch_01073": "Please Select Case Type",
  "xch_01074": "Address Information",
  "xch_01075": "Customer Type",
  "xch_01076": "Due to the bank requirements, please place order only to Classic mode. we will execute auto routing.Balances will be also auto routed accordingly.",
  "xch_01077": "We recommend preparing the corresponding documents listed below before you start, which will significantly save you time. If you encounter any issues during the process, please feel free to contact your account manager at any time.",
  "xch_01078": "Successfully created cases",
  "xch_01079": "Uploaded case form file",
  "xch_01080": "Sorry, all cases failed to be created",
  "xch_01081": "Details of the failed cases",
  "xch_01082": "Download the failed form",
  "xch_01083": "{0} cases failed to process.",
  "xch_01084": "{0} cases failed to be created. You can review the error details, make adjustments, and re-upload.",
  "xch_01085": "All cases created successfully.",
  "xch_01086": "Congratulations, all cases have been successfully created. You can view them on the [Case Management] page.",
  "xch_01087": "Industry Detail Name",
  "xch_01088": "Please enter the industry detail name",
  "xch_01089": "The beneficiary's elements are incomplete, please complete the relevant information",
  "xch_01090": "CNY Mode",
  "xch_01091": "Cross Mode",
  "xch_01092": "Original Mode",
  "xch_01093": "Skip for Now",
  "xch_01094": "Missing Document Information",
  "xch_01095": "Either Addressline1 or Post Code must be provided",
  "xch_01096": "Material Type",
  "xch_01097": "Enter order number, query multiple separated by space",
  "xch_01098": "{0} transfers are abnormal",
  "xch_01099": "All transfers are abnormal",
  "xch_01100": "The batch transfer is complete, but the following transfers are still in progress or have encountered issues. Please review the reasons listed below.",
  "xch_01101": "Details of abnormal transfers",
  "xch_01102": "This order is being processed by the bank. Please go to the order details page to follow up on the status. If you have any questions, please contact your account manager.",
  "xch_01103": "{0} transfers in progress",
  "xch_01104": "Upload Supporting Materials",
  "xch_01105": "Upload Customer Information",
  "xch_01106": "Customer  Information",
  "xch_01107": "Supporting Materials",
  "xch_01108": "Successfully uploaded files",
  "xch_01109": "Upload Results",
  "xch_01110": "Submission Complete",
  "xch_01111": "Your batch of supporting materials is being uploaded and processed. Please wait.",
  "xch_01112": "Your materials are being uploaded.",
  "xch_01113": "Preparing. If there are many materials, it may take some time. You can close this page and return later.",
  "xch_01114": "Number of Successful Materials",
  "xch_01116": "Folder Name",
  "xch_01117": "Customer Name",
  "xch_01118": "Click to view the upload guidelines.",
  "xch_01119": "Provided",
  "xch_01120": "Not provided",
  "xch_01121": "This supporting material is not linked to an order",
  "xch_01122": "Message",
  "xch_01123": "Upload your file (in zip format), which should include supporting materials. ",
  "xch_01124": "Supports single upload. Please do not upload documents that are not related to the bulk addition of supporting material information.",
  "xch_01125": "Please upload a file in ZIP format, which should include supporting materials.",
  "xch_01126": "Number of Failed Submissions",
  "xch_01127": "Export Failed Material Information",
  "xch_01128": "Export Successful.",
  "xch_01129": "When the Payment Type is not DepositPayment, the logistics company must be provided. If there are multiple logistics companies, separate them with a |.",
  "xch_01130": "When the Payment Type is not DepositPayment, the logistics tracking number must be provided. If there are multiple tracking numbers, separate them with a |.",
  "xch_01131": "Please provide the full Chinese business name as registered with the industrial and commercial authority.",
  "xch_01132": "This information is mandatory: If the recipient account type is a business, the account name must match the recipient company name; if the recipient account type is individual, fill in the personal bank account name.",
  "xch_01133": "When the recipient account type is a business account with the same name as the recipient, the bank branch details must be provided, specifying the sub-branch.",
  "xch_01134": "When the recipient account type is individual, the Mainland China ID document of the recipient must be provided.",
  "xch_01135": "The payment type format is incorrect. Please ensure it is one of the following:\n1.DepositPayment\n2.FullPayment\n3.FinalPayment\n4.Other",
  "xch_01136": "This order has not been updated for more than 3 days. A secondary confirmation is required when initiating a transfer. If the order is not updated within 15 days, the system will automatically cancel the order.",
  "xch_01137": "This order was created over 3 days ago. Do you wish to proceed with the transfer?",
  "xch_01138": "The following orders have been created for more than 3 days. Do you confirm to proceed with the transfer?"
}
export const RELATIONSHIP_MAP = {
  Spouse: '配偶',
  Parent: '父母',
  Children: '子女',
  Sibling: '兄弟姐妹',
  Grandparent: '祖父母、外祖父母',
  Grandchildren: '子孙女、外子孙女',
  Self: '自己',
};

// 手写签名关系表
export const ESIGN_RELATIONSHIP_MAP = {
  Spouse: '配偶',
  ParentChildren: '父母 / 子女',
  Sibling: '兄弟姐妹',
  GrandparentGrandchildren: '（外）祖父母 /（外）孙子女',
};

export const XLSX_EXPORT_FIELD_WIDTH_MAP = {
  id: 35,
  accountNumber: 25,
  orderNo: 25,
  created: 20,
  completedTime: 20,
  currencyPair: 20,
  name: 20,
  status: 20,
};
// 模式文案
export const TRANSACTION_MODE_LABEL = {
  quick: {
    id: 'xch_00309',
    defaultMessage: 'Quick Mode',
    supportId: 'xch_00333',
    explain: 'xch_00551',
  },
  low: {
    id: 'xch_00308',
    defaultMessage: 'Low Cost Mode (Coming Soon)',
    supportId: 'xch_00332',
    explain: 'xch_00552',
  },
  stable: {
    id: 'xch_00481',
    defaultMessage: 'Stable Mode',
    supportId: 'xch_00499',
    explain: 'xch_00553',
  },
  classic: {
    id: 'xch_00535',
    defaultMessage: 'Classic Mode',
    supportId: 'xch_00620',
    explain: 'xch_00510',
  },
  xpress: {
    id: 'xch_00536',
    defaultMessage: 'Xpress Mode',
    supportId: 'xch_00621',
    explain: 'xch_00526',
  },
};

export const TRANSACTION_MODE_MAP = {
  classic: {
    id: 'xch_00535',
    defaultMessage: 'Classic Mode',
    value: 'classic',
  },
  xpress: {
    id: 'xch_00536',
    defaultMessage: 'Xpress Mode',
    value: 'xpress',
  },
};

export const TRANSACTION_MODE_AVAILABLE_TIME = {
  quick: '9:30--15:30 UTC+08:00',
  low: '9:00--15:00 UTC+08:00',
  stable: '7 * 24',
};
export const BATCH_FACTORS_MAP = {
  CLASSIC: ['obank', 'obank_cny'],
  XPRESS: ['kcb', 'hfbank', 'helipay'],
  TRADE: ['helipay_b2b'],
};

export const BATCH_MODE_DETAIL = {
  CLASSIC: {
    modeTitle: 'xch_00535',
    title: 'xch_00510',
    Fee: '1 USD',
    bodyTitle: 'xch_00511',
    kycLabel: [
      'xch_00512',
      'xch_00513',
      'xch_00514',
      'xch_00515',
      'xch_00516',
      'xch_00517',
      'xch_00518',
      'xch_00519',
      'xch_00520',
      'xch_00521',
      'xch_00522',
      'xch_00523',
      'xch_00524',
      'xch_00525',
    ],
    xlsx: 'https://public.xcurrency.com/payout/Send_To_Bank_Accounts_Template_03.xlsx',
    downloadName: 'Send_To_Bank_Accounts_Template_03.xlsx',
  },
  XPRESS: {
    modeTitle: 'xch_00536',
    title: 'xch_00526',
    Fee: '2 USD',
    bodyTitle: 'xch_00511',
    kycLabel: ['xch_00527', 'xch_00521', 'xch_00525', 'xch_00522'],
    xlsx: 'https://public.xcurrency.com/payout/Send_To_Bank_Accounts_Template_04.xlsx',
    downloadName: 'Send_To_Bank_Accounts_Template_04.xlsx',
  },
  TRADE: {
    // TODO
    modeTitle: 'xch_00726',
    title: 'xch_00727',
    Fee: '2 USD',
    bodyTitle: 'xch_00511',
    kycLabel: ['xch_00527', 'xch_00521', 'xch_00525', 'xch_00522'],
    xlsx: 'https://public.xcurrency.com/payout/Send_To_Bank_Accounts_Template_Business_01.xlsx',
    downloadName: 'Send_To_Bank_Accounts_Template_Business_01.xlsx',
  },
};

// 下发模式 : CLASSIC XPRESS
export const TRANSFERS_BATCH_MODE = 'TRANSFERS_BATCH_MODE';

// 不同交易模式对应的币种
export const MODE_CURRENCY = {
  stable: 'XC',
  low: 'CNY',
  quick: 'CNY',
};

// XC的预计交易时常
export const TRANSCATION_DELAY = {
  deposit: 20,
  exchange: 100,
};
export const BATCH_PROCESS_STATUS_ENUM = {
  completed: {
    color: 'success',
    label: 'xch_00618',
  },
  allocating: {
    color: 'warning',
    label: 'xch_00619',
  },
};

export const NumberProps = {
  formatter: (value: any) => {
    //指定输入框展示值的格式
    const reg1 = `${value}`.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
    return reg1.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
};

// 用户授权类型
export const AuthorizedType = {
  PartnerView: 'PartnerView',
  PaetnerAdmin: 'PaetnerAdmin',
  PartnerViewAndAdmin: 'PartnerViewAndAdmin',
  PartnerTransfer: 'PartnerTransfer',
};

/**
 * b2b款项性质
 * DepositPayment 预付款
 * FullPayment 全款
 * FinalPayment 尾款
 * Other 其他款项
 */
export const PaymentType = {
  DepositPayment: {
    id: 'xch_00769',
    defaultMessage: 'DepositPayment',
    zh_Text: '预付款',
  },
  FullPayment: {
    id: 'xch_00770',
    defaultMessage: 'FullPayment',
    zh_Text: '全款',
  },
  FinalPayment: {
    id: 'xch_00771',
    defaultMessage: 'FinalPayment',
    zh_Text: '尾款',
  },
  Other: {
    id: 'xch_00772',
    defaultMessage: 'Other',
    zh_Text: '其他款项',
  },
};
/**
 * b2b交易模式
 */
export const B2BTransactionMode = {
  cnyMode: {
    id: 'xch_01090',
    defaultMessage: 'CNY Mode',
    zh_Text: 'CNY 模式',
  },
  crossMode: {
    id: 'xch_01091',
    defaultMessage: 'Cross-Currency Mode',
    zh_Text: '跨币种模式',
  },
  originalMode: {
    id: 'xch_01092',
    defaultMessage: 'Original Currency Mode',
    zh_Text: '原币种模式',
  },
};

/**
 * 交易模式枚举
 * 拆分 B2B业务模式 和 C2C业务模式
 * 目前只在换汇时使用
 */
export const TransferModeMap = {
  B2B: ['cnyMode', 'originalMode', 'crossMode'],
  C2C: ['xpress', 'classic'],
};

/**
 * 是否为单一业务交易模式
 * @param transferMode
 * @returns
 */
export const isASingleBusinessTradingModel = function (transferMode: string[]) {
  const result = {
    isASingleBusiness: false,
    transferMode: [] as string[],
  };
  // 判断transferMode 中的值 是否在单一交易模式中 根据TransferModeMap的分类来判断
  if (!transferMode || !Array.isArray(transferMode) || transferMode.length < 1) {
    return result;
  }
  // 判断transferMode 中的值 是否在单一交易模式中 根据TransferModeMap的分类来判断
  const { B2B, C2C } = TransferModeMap;

  // 判断 transferMode 是否完全属于 B2B
  const isB2BMode = transferMode.every((mode) => B2B.includes(mode));

  if (isB2BMode) result.transferMode.push('b2b');

  // 判断 transferMode 是否完全属于 C2C
  const isC2CMode = transferMode.every((mode) => C2C.includes(mode));

  if (isC2CMode) result.transferMode.push('c2c');

  // 如果 transferMode 完全属于 B2B 或 C2C 之一，则返回 true
  result.isASingleBusiness = isB2BMode || isC2CMode;

  return result;
};

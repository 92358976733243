// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/usr/local/bin/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/usr/local/bin/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/tauth",
        "layout": false,
        "routes": [
          {
            "path": "/tauth",
            "redirect": "/tauth/tauth/login",
            "exact": true
          },
          {
            "path": "/tauth/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Auth__Login' */'/usr/local/bin/src/pages/Auth/Login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/tauth/callback",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Auth__CallBack' */'/usr/local/bin/src/pages/Auth/CallBack'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/tauth/simple-login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Auth__LNLoginPage' */'/usr/local/bin/src/pages/Auth/LNLoginPage'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "esign",
        "path": "/esign",
        "layout": false,
        "hideInMenu": true,
        "routes": [
          {
            "path": "/esign",
            "redirect": "/esign/index",
            "exact": true
          },
          {
            "name": "esign-index",
            "hideInMenu": true,
            "path": "/esign/esign-index",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ESign__index' */'/usr/local/bin/src/pages/ESign/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "nda",
            "hideInMenu": true,
            "path": "/esign/nda",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companyManagement__company__SigningNDA' */'/usr/local/bin/src/pages/companyManagement/company/SigningNDA'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "appendix1",
            "hideInMenu": true,
            "path": "/esign/appendix1",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companyManagement__company__Questionnaire__question1' */'/usr/local/bin/src/pages/companyManagement/company/Questionnaire/question1'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "appendix2",
            "hideInMenu": true,
            "path": "/esign/appendix2",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companyManagement__company__Questionnaire__question2' */'/usr/local/bin/src/pages/companyManagement/company/Questionnaire/question2'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "appendix3",
            "hideInMenu": true,
            "path": "/esign/appendix3",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companyManagement__company__Questionnaire__question3' */'/usr/local/bin/src/pages/companyManagement/company/Questionnaire/question3'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/exception",
        "layout": false,
        "routes": [
          {
            "path": "/exception",
            "routes": [
              {
                "path": "/exception/401",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__401' */'/usr/local/bin/src/pages/401'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/exception/403",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__403' */'/usr/local/bin/src/pages/403'), loading: LoadingComponent}),
                "exact": true
              },
              {
                "path": "/exception/404",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/usr/local/bin/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/usr/local/bin/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/usr/local/bin/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "welcome",
        "path": "/welcome",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Welcome' */'/usr/local/bin/src/pages/Welcome'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "layout",
        "path": "/index.html",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Layout' */'/usr/local/bin/src/pages/Layout'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "layout",
        "path": "/",
        "hideInMenu": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Layout' */'/usr/local/bin/src/pages/Layout'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "name": "wallets",
        "path": "/wallets",
        "access": "canAccess",
        "routes": [
          {
            "path": "/wallets",
            "redirect": "/wallets/balance",
            "exact": true
          },
          {
            "name": "balance",
            "path": "/wallets/balance",
            "access": "canAccess",
            "hideChildrenInMenu": true,
            "routes": [
              {
                "path": "/wallets/balance",
                "redirect": "/wallets/balance/list",
                "exact": true
              },
              {
                "name": "balanceEntry",
                "path": "/wallets/balance/list",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallets__balance__list' */'/usr/local/bin/src/pages/wallets/balance/list'), loading: LoadingComponent}),
                "access": "canAccess",
                "exact": true
              },
              {
                "name": "detail",
                "path": "/wallets/balance/detail",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallets__balance__detail' */'/usr/local/bin/src/pages/wallets/balance/detail'), loading: LoadingComponent}),
                "access": "canAccess",
                "exact": true
              },
              {
                "name": "deposit",
                "path": "/wallets/balance/deposit",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallets__balance__deposit' */'/usr/local/bin/src/pages/wallets/balance/deposit'), loading: LoadingComponent}),
                "access": "canAccess",
                "exact": true
              },
              {
                "name": "exchange",
                "path": "/wallets/balance/exchange",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallets__balance__exchange' */'/usr/local/bin/src/pages/wallets/balance/exchange'), loading: LoadingComponent}),
                "access": "canAccess",
                "exact": true
              },
              {
                "name": "notifications",
                "path": "/wallets/balance/notifications",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallets__balance__notifications' */'/usr/local/bin/src/pages/wallets/balance/notifications'), loading: LoadingComponent}),
                "access": "canAccess",
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "name": "batchTransfers",
        "path": "/batch-transfers",
        "access": "canAccess",
        "routes": [
          {
            "path": "/batch-transfers",
            "redirect": "/batch-transfers/personal-payout-transfer",
            "exact": true
          },
          {
            "name": "personal",
            "path": "/batch-transfers/personal-payout-transfer",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__batchTransfers__personalPayoutTransfer' */'/usr/local/bin/src/pages/batchTransfers/personalPayoutTransfer'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          },
          {
            "name": "business",
            "path": "/batch-transfers/business-payout-transfer",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__batchTransfers__businessPayoutTransfer' */'/usr/local/bin/src/pages/batchTransfers/businessPayoutTransfer'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          }
        ]
      },
      {
        "name": "balanceTransactions",
        "path": "/balance-transactions",
        "access": "canAccess",
        "routes": [
          {
            "path": "/balance-transactions",
            "redirect": "/balance-transactions/balance/list",
            "exact": true
          },
          {
            "name": "balance",
            "path": "/balance-transactions/balance/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__balanceTransactions__balance__list' */'/usr/local/bin/src/pages/balanceTransactions/balance/list'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          },
          {
            "name": "balanceDetail",
            "path": "/balance-transactions/balance/detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__balanceTransactions__balance__detail' */'/usr/local/bin/src/pages/balanceTransactions/balance/detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "personalTransactions",
        "path": "/personal-transactions",
        "access": "canPartnerViewAndAdmin",
        "routes": [
          {
            "path": "/personal-transactions",
            "redirect": "/personal-transactions/transfer/list",
            "exact": true
          },
          {
            "name": "transfer",
            "path": "/personal-transactions/transfer/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personalTransactions__transfer__list' */'/usr/local/bin/src/pages/personalTransactions/transfer/list'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          },
          {
            "name": "batch",
            "path": "/personal-transactions/transfer/batch",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personalTransactions__transfer__batch' */'/usr/local/bin/src/pages/personalTransactions/transfer/batch'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          },
          {
            "name": "batchTransactionList",
            "path": "/personal-transactions/transfer/batchTransactionList",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personalTransactions__transfer__batchTransactionList' */'/usr/local/bin/src/pages/personalTransactions/transfer/batchTransactionList'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          },
          {
            "name": "transferDetail",
            "path": "/personal-transactions/transfer/detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personalTransactions__transfer__detail' */'/usr/local/bin/src/pages/personalTransactions/transfer/detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "orderSearch",
            "path": "/personal-transactions/order-search",
            "access": "canPartnerView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__orderSearch__transfer__list__index' */'/usr/local/bin/src/pages/orderSearch/transfer/list/index'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "businessTransactions",
        "path": "/business-transactions",
        "access": "canAccess",
        "routes": [
          {
            "path": "/business-transactions",
            "redirect": "/business-transactions/transfer/list",
            "exact": true
          },
          {
            "name": "transfer",
            "path": "/business-transactions/transfer/list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__businessTransactions__transfer__list' */'/usr/local/bin/src/pages/businessTransactions/transfer/list'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          },
          {
            "name": "batch",
            "path": "/business-transactions/transfer/batch",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__businessTransactions__transfer__batch' */'/usr/local/bin/src/pages/businessTransactions/transfer/batch'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          },
          {
            "name": "batchTransactionList",
            "path": "/business-transactions/transfer/batchTransactionList",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__businessTransactions__transfer__batchTransactionList' */'/usr/local/bin/src/pages/businessTransactions/transfer/batchTransactionList'), loading: LoadingComponent}),
            "access": "canAccess",
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "transferDetail",
            "path": "/business-transactions/transfer/detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__businessTransactions__transfer__detail' */'/usr/local/bin/src/pages/businessTransactions/transfer/detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "global-payout",
        "path": "/global-payout",
        "access": "canAccess",
        "hideInMenu": true,
        "routes": [
          {
            "path": "/global-payout",
            "redirect": "/global-payout/order/list",
            "exact": true
          },
          {
            "name": "order-list",
            "path": "/global-payout/order-list",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__GlobalPayout' */'/usr/local/bin/src/pages/GlobalPayout'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          },
          {
            "name": "order-detail",
            "path": "/global-payout/order-detail",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__GlobalPayout__OrderDetail' */'/usr/local/bin/src/pages/GlobalPayout/OrderDetail'), loading: LoadingComponent}),
            "access": "canAccess",
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "myCustomer",
        "path": "/my-customer",
        "access": "canAccess",
        "routes": [
          {
            "path": "/my-customer",
            "redirect": "/my-customer/cases",
            "exact": true
          },
          {
            "name": "cases",
            "path": "/my-customer/cases",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyCustomer__index' */'/usr/local/bin/src/pages/MyCustomer/index'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          },
          {
            "name": "personalCase",
            "path": "/my-customer/cases/personal-case",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyCustomer__CaseDetail' */'/usr/local/bin/src/pages/MyCustomer/CaseDetail'), loading: LoadingComponent}),
            "access": "canAccess",
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "businessCase",
            "path": "/my-customer/cases/business-case",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyCustomer__CaseDetail' */'/usr/local/bin/src/pages/MyCustomer/CaseDetail'), loading: LoadingComponent}),
            "access": "canAccess",
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "add-personal-customer",
            "path": "/my-customer/cases/add-personal-customer",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyCustomer__AddCustomer' */'/usr/local/bin/src/pages/MyCustomer/AddCustomer'), loading: LoadingComponent}),
            "access": "canAccess",
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "add-business-customer",
            "path": "/my-customer/cases/add-business-customer",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyCustomer__AddCustomer' */'/usr/local/bin/src/pages/MyCustomer/AddCustomer'), loading: LoadingComponent}),
            "access": "canAccess",
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "batchUpload",
            "path": "/my-customer/batch-upload",
            "routes": [
              {
                "name": "batchRecipientUpload",
                "path": "/my-customer/batch-upload/recipient-upload",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyCustomer__BatchRecipientUpload' */'/usr/local/bin/src/pages/MyCustomer/BatchRecipientUpload'), loading: LoadingComponent}),
                "hideInMenu": true,
                "access": "canAccess",
                "exact": true
              },
              {
                "name": "batchProofMaterialsUpload",
                "path": "/my-customer/batch-upload/proof-materials-upload",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyCustomer__BatchProofMaterialsUpload' */'/usr/local/bin/src/pages/MyCustomer/BatchProofMaterialsUpload'), loading: LoadingComponent}),
                "access": "canAccess",
                "exact": true
              }
            ],
            "access": "canAccess"
          }
        ]
      },
      {
        "name": "developer",
        "path": "/developer",
        "access": "canAccess",
        "routes": [
          {
            "path": "/developer",
            "redirect": "/developer/xtransfer-payout-api",
            "exact": true
          },
          {
            "name": "xtransfer-payout-api",
            "path": "/developer/xtransfer-payout-api",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developer__xtransfer-payout-api' */'/usr/local/bin/src/pages/developer/xtransfer-payout-api'), loading: LoadingComponent}),
            "access": "canAccess",
            "exact": true
          }
        ]
      },
      {
        "name": "riskControl",
        "path": "/risk-control",
        "access": "canPartnerViewAndAdmin",
        "routes": [
          {
            "path": "/risk-control",
            "redirect": "/risk-control/support-docs",
            "exact": true
          },
          {
            "name": "support-docs",
            "path": "/risk-control/support-docs",
            "access": "canAccess",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RiskControl__SupportDocs__index' */'/usr/local/bin/src/pages/RiskControl/SupportDocs/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "channel-support-docs",
            "path": "/risk-control/channel-support-docs",
            "access": "canPartnerView",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RiskControl__SupportDocs__index' */'/usr/local/bin/src/pages/RiskControl/SupportDocs/index'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "channel-order-detail",
            "path": "/risk-control/channel-order-detail",
            "hideInMenu": true,
            "access": "canPartnerViewAndAdmin",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__personalTransactions__transfer__detail' */'/usr/local/bin/src/pages/personalTransactions/transfer/detail'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "onBoarding",
        "hideInMenu": true,
        "menuRender": false,
        "path": "/onboarding",
        "routes": [
          {
            "path": "/onboarding",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OnBoarding' */'/usr/local/bin/src/pages/OnBoarding'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/onboarding/complete",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OnBoarding__Complete' */'/usr/local/bin/src/pages/OnBoarding/Complete'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "name": "company-management",
        "path": "/company-management",
        "access": "canNotPartnerView",
        "routes": [
          {
            "name": "info",
            "path": "/company-management/info",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companyManagement__company' */'/usr/local/bin/src/pages/companyManagement/company'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "name": "add",
            "path": "/company-management/add",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companyManagement__company__AddCompany' */'/usr/local/bin/src/pages/companyManagement/company/AddCompany'), loading: LoadingComponent}),
            "hideInMenu": true,
            "menuRender": false,
            "exact": true
          },
          {
            "name": "nda",
            "hideInMenu": true,
            "path": "/company-management/nda",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companyManagement__company__SigningNDA' */'/usr/local/bin/src/pages/companyManagement/company/SigningNDA'), loading: LoadingComponent}),
            "menuRender": false,
            "exact": true
          },
          {
            "name": "due-diligence-form",
            "hideInMenu": true,
            "path": "/company-management/due-diligence-form",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companyManagement__company__Questionnaire__DueDiligenceForm' */'/usr/local/bin/src/pages/companyManagement/company/Questionnaire/DueDiligenceForm'), loading: LoadingComponent}),
            "menuRender": false,
            "exact": true
          },
          {
            "name": "aml-ctf-scp-questionnaire",
            "hideInMenu": true,
            "path": "/company-management/aml-ctf-scp-questionnaire",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companyManagement__company__Questionnaire__AmlCtfScpQuestionnaire' */'/usr/local/bin/src/pages/companyManagement/company/Questionnaire/AmlCtfScpQuestionnaire'), loading: LoadingComponent}),
            "menuRender": false,
            "exact": true
          },
          {
            "name": "contract-management",
            "path": "/company-management/contract-management",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__companyManagement__contract' */'/usr/local/bin/src/pages/companyManagement/contract'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/company-management",
            "redirect": "/company-management/info",
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/usr/local/bin/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}

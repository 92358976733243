import type { GetApiMerchantBatchTransferStatusResponse } from '@/services/ytt_apis/apiMerchant/batch';
import { useState } from 'react';

export default function useB2BBatchTransferDataSource() {
  const [dataSource, setDataSource] = useState<
    NonNullable<GetApiMerchantBatchTransferStatusResponse['data']>
  >({});

  return {
    dataSource,
    updateDataSource: setDataSource,
  };
}

import { Settings as LayoutSettings } from '@ant-design/pro-layout';

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
  publicPath?: string;
} = {
  navTheme: 'light',
  // 拂晓蓝
  primaryColor: '#1890ff',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: true,
  fixSiderbar: true,
  colorWeak: false,
  title: 'xCurrency Hubs Partner Platform',
  pwa: false,
  logo: '//public.tratao.com/icon/logo.svg',
  iconfontUrl: '//at.alicdn.com/t/c/font_4439984_cnclmdvs73k.js',
  publicPath: '',
  // footerRender: false,
};

export default Settings;

import Cookies from 'js-cookie';
import Big from 'big.js';
import { request } from 'umi';
import { getApiMerchantInfoGet } from '@/services/ytt_apis/apiMerchant/info';
import { BATCH_FACTORS_MAP } from '@/config';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

/* eslint no-useless-escape:0 */
// const reg =
//   /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

// export const isUrl = (path: string): boolean => reg.test(path);

// export const isAntDesignPro = (): boolean => {
//   if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
//     return true;
//   }
//   return window.location.hostname === 'preview.pro.ant.design';
// };

// // 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
// export const isAntDesignProOrDev = (): boolean => {
//   const { NODE_ENV } = process.env;
//   if (NODE_ENV === 'development') {
//     return true;
//   }
//   return isAntDesignPro();
// };

export function getQueryString(name: string) {
  const regex = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const result = window.location.search.slice(1).match(regex);
  if (result != null) return decodeURI(result[2]);
  return null;
}

export function getLoginFailedTime() {
  let time: number = 0;
  if (Cookies.get('tratao-portal-pro-login-failed')) {
    time = Number(Cookies.get('tratao-portal-pro-login-failed'));
  }
  time += 1;
  // 5 mins to expire
  const expireTime = new Date(new Date().getTime() + 300 * 1000);
  Cookies.set('tratao-portal-pro-login-failed', String(time), {
    expires: expireTime,
  });
  return time;
}

export function setToken(token: string | null | undefined) {
  if (token === undefined || token == null) {
    Cookies.remove('tratao-portal-pro-token');
    return;
  }
  // 12 hours to expire
  const expireTime = new Date(new Date().getTime() + 12 * 3600 * 1000);
  Cookies.set('tratao-portal-pro-token', token, {
    expires: expireTime,
  });
}

export function getToken() {
  return REACT_APP_ENV !== 'dev'
    ? Cookies.get('tratao-portal-pro-token')
    : '03dc699c577a410c5c6ff1675dde8191';
  // : '90ee8c71c8f14faf70623d125ab99dec';
}

export const roundNum = (n: number, dp = 2) => new Big(n).round(dp).toNumber();

// 兼容 Chrome / Firefox / Safari
export const downloadFile = (url: string, filename?: string) => {
  request(url, {
    method: 'get',
    responseType: 'blob',
    prefix: '',
  }).then((blob) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename || link.href.split('/').pop() || '';
    link.click();
    URL.revokeObjectURL(link.href);
  });
};

export const openInNewTab = (href: string) => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    rel: 'noopener noreferrer',
    href: href,
  }).click();
};

export const setAvailableModes = async () => {
  let modes: string[] = [];

  const res = await getApiMerchantInfoGet();
  if (res?.status === '1') {
    if (res.data?.depositChannels && res.data?.depositChannels?.length > 0) {
      modes = [];
      if (res.data?.depositChannels?.includes('obank')) {
        modes.push('quick');
      }
      if (res.data?.depositChannels?.includes('cmbc')) {
        modes.push('low');
      }
      if (res.data?.depositChannels?.includes('tng')) {
        modes.push('stable');
      }
      if (res.data?.depositChannels?.includes('helipay_b2b')) {
        modes.push('trade'); // b2b货贸
      }
      if (
        BATCH_FACTORS_MAP.CLASSIC.filter((item: string) => res.data?.outChannels?.includes(item))
          .length > 0
      ) {
        modes.push('classic');
      }

      if (
        BATCH_FACTORS_MAP.XPRESS.filter((item: string) => res.data?.outChannels?.includes(item))
          .length > 0
      ) {
        modes.push('xpress');
      }
    }
  }
  if (modes.length === 0) modes = ['low'];
  localStorage.setItem('availableModes', modes.join(','));
};

export const getAvailableModes = () => {
  const modeStr = localStorage.getItem('availableModes');
  return modeStr?.split(',') ?? ['low'];
};

export const throttle = (fn, delay = 4600) => {
  let timer;
  return function () {
    if (timer) return;
    if (fn instanceof Function) {
      fn();
    }
    timer = setTimeout(() => {
      timer = null;
    }, delay);
  };
};

export const dataURItoBlob = function (dataURI: string) {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const isTrataoDomain = location.host.includes('tratao.com');

export const getFileName = (rawUrl: string) => {
  const noParamsUrl = rawUrl.split('?')[0];
  const lastSlashIndex = noParamsUrl.lastIndexOf('/');
  const fileName = noParamsUrl.slice(lastSlashIndex + 1);
  return fileName;
};

/**
 * 生成pdf预览图URL
 * @param url
 * @returns
 */
export const getPdfPreviewUrl = async function (url: string) {
  const documentRes = await pdfjs.getDocument({
    url,
    cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
    cMapPacked: true,
  }).promise;
  const page = await documentRes.getPage(1);
  const scale = 1.5;
  const viewport = page.getViewport({ scale });
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  const renderContext = {
    canvasContext: context!,
    viewport: viewport,
  };
  let result = await page.render(renderContext).promise;
  result = canvas.toDataURL();

  return result;
};

/**
 * 过滤后端资源url的token
 */
export function removeQueryParamsFromUrl(url: string) {
  const urlParts = url.split('?');
  if (urlParts.length >= 2) {
    const baseUrl = urlParts.shift();
    return baseUrl;
  }
  return url;
}

/**
 * 判断对象是否为空
 */
export function isEmpty(obj: Record<string, any>) {
  let result = true;
  for (const key in obj) {
    if (key) result = false;
    break;
  }
  return result;
}

/**
 * 判断是否是不带token的私有资源
 * @param url
 * @returns
 */
export function isPrivateUrlWithoutQuery(url: string) {
  try {
    const parsedUrl = new URL(url);
    const hostCheck =
      parsedUrl.host.includes('private.tratao.com') ||
      parsedUrl.host.includes('private.xcurrency.com');
    const noQueryParams = parsedUrl.search === '';

    return hostCheck && noQueryParams;
  } catch (error) {
    console.error('Invalid URL:', error);
    return false;
  }
}

// 返回当前时区与该日期所在时区之间的时间差（以毫秒为单位）
function getTimezoneOffsetMS(date: Date) {
  const time = date.getTime();
  const utcTime = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );
  return time - utcTime;
}

// 计算当前时区与1899年12月30日时区之间的时间差（以毫秒为单位）
const importBugHotfixDiff = (function () {
  const baseDate = new Date(1899, 11, 30, 0, 0, 0);
  const dnThreshAsIs = (new Date().getTimezoneOffset() - baseDate.getTimezoneOffset()) * 60000;
  const dnThreshToBe = getTimezoneOffsetMS(new Date()) - getTimezoneOffsetMS(baseDate);
  return dnThreshAsIs - dnThreshToBe;
})();

// 修复日期的精度损失（需要注意传入的 Date 对象必须是已经转换为当前时区 Date 对象）
export function fixPrecisionLoss(date: Date) {
  return new Date(date.getTime() - importBugHotfixDiff);
}

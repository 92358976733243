import { request } from 'umi';
import type { RequestFunctionParams } from 'yapi-to-typescript';
import { RequestBodyType } from 'yapi-to-typescript';

export interface RequestOptions {
  headers?: Record<string, any>;
  errorHandler?: (error: any) => void;
  hasFileData?: boolean;
}

export enum RequestErrorType {
  NetworkError = 'NetworkError',
  StatusError = 'StatusError',
  BusinessError = 'BusinessError',
}

type RequestType = 'json' | 'form';

export default async <TResponseData>(
  payload: RequestFunctionParams,
  options?: RequestOptions,
): Promise<TResponseData> => {
  try {
    // console.log('http payload ', payload);
    // console.log('http options', options);

    // 路径参数和查询参数自动合并到path
    // https://fjc0k.github.io/yapi-to-typescript/handbook/request.html#path
    const url = payload.path;

    const hasFileData = options?.hasFileData ?? false;

    const requestOptions = {
      ...options,
      method: payload.method,
      headers: {
        ...payload.requestHeaders,
        ...options?.headers,
        ...(hasFileData || payload.hasFileData
          ? {}
          : payload.requestBodyType === RequestBodyType.json
          ? { 'Content-Type': 'application/json' }
          : payload.requestBodyType === RequestBodyType.form
          ? { 'Content-Type': 'application/x-www-form-urlencoded' }
          : {}),
      },
      requestType: (hasFileData ? 'form' : 'json') as RequestType,
      data:
        hasFileData || payload.hasFileData
          ? payload.getFormData()
          : payload.requestBodyType === RequestBodyType.json
          ? JSON.stringify(payload.data)
          : payload.requestBodyType === RequestBodyType.form
          ? Object.keys(payload.data)
              .filter((key) => payload.data[key] != null)
              .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(payload.data[key])}`)
              .join('&')
          : undefined,
    };
    delete requestOptions.hasFileData;

    // console.log('requestOptions', requestOptions);

    return request(url, requestOptions);
  } catch (error) {
    // Do something

    // Rethrow error
    throw error;
  }
};

/*
  接口轮询方法

  Reference:
  - [Polling in JavaScript](https://levelup.gitconnected.com/polling-in-javascript-ab2d6378705a)
  - [The executor return value is ignored](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/Promise#description)
*/
export const poll = ({
  fn,
  validate,
  interval,
  maxAttempts,
}: {
  fn: () => Promise<any>;
  validate: (requestedData: any) => boolean;
  interval: number;
  maxAttempts: number;
}) => {
  // console.log('Start poll...');
  let attempts = 0;

  const executePoll = async (resolve, reject) => {
    let result;
    let isValid = false;
    try {
      result = await fn();
      isValid = validate(result);
    } catch (error) {
      isValid = false;
    }

    attempts++;

    if (isValid) {
      return resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'));
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };

  return new Promise(executePoll);
};

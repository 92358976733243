import { useState, useEffect } from 'react';
import { getApiMerchantInfoGet } from '@/services/ytt_apis/apiMerchant/info';
import type { GetApiMerchantInfoGetResponse } from '@/services/ytt_apis/apiMerchant/info';
import { useModel } from 'umi';

export default function useMerchantInfo() {
  const { initialState } = useModel('@@initialState');

  const [merchantInfo, setMerchantInfo] = useState<NonNullable<
    GetApiMerchantInfoGetResponse['data']
  > | null>(null);

  useEffect(() => {
    const { currentUser } = initialState || {};
    if (currentUser) {
      getApiMerchantInfoGet().then((resp) => {
        if (resp?.status === '1') {
          setMerchantInfo(resp?.data || null);
        }
      });
    }
  }, [initialState]);

  return {
    merchantInfo,
    updateMerchantInfo: setMerchantInfo,
  };
}

import { request } from 'umi';
import sha1 from 'js-sha1';
import { stringify } from 'qs';
import conf from '../../../config/deploy';

export async function loginByTAuth(code: string) {
  let appkey: string;
  let secret: string;

  if (REACT_APP_ENV === 'dev') {
    appkey = conf.appkey;
    secret = conf.secret;
  } else {
    const host = window.origin;
    appkey = conf['oauth'][host].appkey;
    secret = conf['oauth'][host].secret;
  }

  return request<AUTH.Token>('/api/tauth/token', {
    method: 'POST',
    requestType: 'form',
    data: { appkey, key: sha1(secret + code) },
  });
}

export async function getTAuthUserInfo() {
  return request<AUTH.CurrentUser>('/api/tauth/info', {
    method: 'get',
  });
}

export async function queryUserRoles(params?: Record<string, any>) {
  return request<AUTH.RoleData>(`/api/backend/manage/api/auth/user/roles?${stringify(params)}`);
}

import { useState } from 'react';
import { GetApiMerchantBatchMaterialStatusResponse } from '@/services/ytt_apis/apiMerchant/batch';

export default function useAddSupportingMaterialsDataSource() {
  const [dataSource, setDataSource] = useState<GetApiMerchantBatchMaterialStatusResponse['data']>(
    {},
  );

  return {
    dataSource: dataSource || {},
    updateDataSource: setDataSource,
  };
}

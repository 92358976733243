const handleGenerateConfigOfHost = () => {
  switch (window.origin) {
    case 'https://lightnet-beta.tratao.com':
      return {
        env: 'beta',
        host: 'https://lightnet-beta.tratao.com',
        authHost: 'https://auth-lightnet-beta.tratao.com',
        oauth: {
          'https://lightnet-beta.tratao.com': {
            appkey: '7aed34abde7a406b78f414304748ce80',
            secret: '5f81491920cc4c936e1c5285f3271712',
          },
        },
      };
    default:
      return {
        env: 'beta',
        host: 'https://masspay-beta.xcurrency.com',
        authHost: 'https://auth-beta.xcurrency.com',
        oauth: {
          'https://masspay-beta.xcurrency.com': {
            appkey: 'e778f4f4b5d64d3844994b1f2cc6796a',
            secret: '06b8272ba2094cb841d6c4e7c4e32e81',
          },
        },
      };
  }
};

const config = handleGenerateConfigOfHost();

export default config;

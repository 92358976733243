import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading, RouteContext } from '@ant-design/pro-layout';
import type { RequestConfig, RunTimeLayoutConfig } from 'umi';
import { Link, useLocation } from 'umi';
import { history, setLocale } from 'umi';
import RightContent from '@/components/RightContent';
// import Footer from '@/components/Footer';
import { currentUser as queryCurrentUser, outLogin } from './services/ant-design-pro/api';
import defaultSettings from '../config/defaultSettings';
import type { RequestOptionsInit } from 'umi-request';
import { getTAuthUserInfo, queryUserRoles } from './services/auth/auth';
import {
  getToken,
  getLoginFailedTime,
  getQueryString,
  isTrataoDomain,
  setToken,
} from '@/utils/utils';
import conf from '../config/deploy';
import { stringify } from 'querystring';
import { ConfigProvider, notification } from 'antd';
import UnAccessible from './components/UnAccessible';
import { fixContext } from 'react-activation';
import React, { useEffect } from 'react';
import { getApiMerchantInfoGet } from '@/services/ytt_apis/apiMerchant/info';
import { getPartnerConfigFormServer, getCountryCodeFormServer } from './utils/remote';
import logo from './assets/svg/public/logo.svg';
import * as Sentry from '@sentry/react';

const isDev = REACT_APP_ENV === 'dev';

const loginPath = '/tauth/login';

const environment = conf?.env;

/** 允许访问路径 */
const allowList: string[] = [
  loginPath,
  '/user/login',
  '/tauth/callback',
  '/tauth/login/',
  '/tauth/callback/',
  '/exception/403',
  '/exception/401',
  '/exception/403/',
  '/exception/401/',
  '/esign/esign-index',
  '/esign',
];

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

const LayoutWrapper = function ({ children }) {
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    const antLayoutContainer = Array.from(document.getElementsByClassName('ant-layout')).pop();
    const logoContainer = document.querySelector('.ant-pro-global-header-logo');
    const avaterContainer = document.querySelector('.ant-space-align-center');

    if (!antLayoutContainer) return;
    // 判断路径，添加或移除类名
    if (
      path?.startsWith('/company-management/add') ||
      path?.startsWith('/company-management/due-diligence-form') ||
      path?.startsWith('/company-management/aml-ctf-scp-questionnaire')
    ) {
      if (logoContainer) {
        logoContainer.classList.add('ant-pro-global-header-logo-custom');
      }
      antLayoutContainer.classList.add('custom_background');
      avaterContainer?.classList.add('dark');
    } else {
      antLayoutContainer.classList.remove('custom_background');
      logoContainer?.classList.remove('ant-pro-global-header-logo-custom');
      avaterContainer?.classList.remove('dark');
    }
  }, [location.pathname]);
  return <>{children}</>;
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
  logout?: () => Promise<boolean | undefined>;
}> {
  const fetchUserInfo = async () => {
    try {
      let currentUser;
      let merchantInfo;
      // 获取远程配置
      await getPartnerConfigFormServer();
      await getCountryCodeFormServer();
      if (!isDev) {
        // 优先获取商户信息
        merchantInfo = await getApiMerchantInfoGet();
        currentUser = await getTAuthUserInfo();
        currentUser.token = getToken();
        currentUser.avatar =
          'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png';
      } else {
        const res = await queryCurrentUser();
        currentUser = res.data;
        merchantInfo = await getApiMerchantInfoGet();
      }

      if (!isDev) {
        const result = await queryUserRoles({ userid: currentUser.userid, token: getToken() });
        if (result && result.data) {
          const roles = currentUser.roles?.concat(result.data);
          currentUser.roles = roles;
        }
      }

      if (merchantInfo && merchantInfo?.status === '1') {
        currentUser['forbidden'] = merchantInfo?.data?.forbidden;
        currentUser['merchant'] = merchantInfo?.data;
        if (
          ['reject', 'onboarding', 'complete'].includes(merchantInfo?.data?.state || 'none') &&
          merchantInfo?.data?.fillStep == 4
        ) {
          if (merchantInfo?.data?.state === 'onboarding') {
            history.push('/welcome');
            currentUser['state'] = merchantInfo?.data?.state;
            return currentUser;
          }
          if (merchantInfo?.data?.state === 'reject' && !location.pathname.includes('/esign')) {
            history.push('/company-management');
            currentUser['state'] = merchantInfo?.data?.state;
            return currentUser;
          }
        } else {
          // history.push('/onboarding');
          // if (!location.pathname.includes('/esign') && merchantInfo?.data?.state === 'onboarding') {
          if (!location.pathname.includes('/esign') && merchantInfo?.data?.state !== 'complete') {
            if (currentUser?.roles?.includes('partnerView')) {
              history.push('/welcome');
            } else {
              history.push('/company-management');
            }
          }
        }
      } else {
        if (currentUser.roles?.includes('partnerView')) {
          history.push('/welcome');
        } else {
          history.push('/company-management');
        }
      }

      return currentUser;
    } catch (error) {
      // 如果没有登录，重定向到 login
      if (!allowList.includes(location.pathname)) {
        history.push(loginPath);
      }
    }
    return undefined;
  };

  /**
   * 退出登录，并且将当前的 url 保存
   */
  const loginOut = async () => {
    const { pathname } = history.location;
    if (pathname !== '/tauth/login') {
      if (REACT_APP_ENV !== 'dev') {
        const { authHost } = conf;
        const logoutPath = `${authHost}/tauth/logout`;
        const callback = `${window.location.origin}/exception/401`;
        window.location.href = `${logoutPath}?callback=${callback}`;
      } else {
        await outLogin();
        history.replace({
          pathname: '/tauth/login',
          search: stringify({
            redirect: pathname,
          }),
        });
      }
    }
    return true;
  };

  /**
   * init setting
   */
  // let settings = {};
  // if (!isDev) {
  //   settings = {
  //     headerRender: false,
  //     footerRender: false,
  //     contentStyle: { margin: 0 },
  //   };
  // }

  // 设置语言环境（当前仅支持中英文）
  let locale = localStorage.getItem('umi_locale');
  if (!locale || !['zh-CN', 'en-US'].includes(locale)) {
    locale = navigator.language.startsWith('zh-') ? 'zh-CN' : 'en-US';
    setLocale(locale);
    localStorage.setItem('umi_locale', locale);
  }

  // 设置全局字体
  if (locale === 'zh-CN') {
    // 暂时不设置中文字体
    // document.getElementsByTagName('body')[0].style.setProperty('font-family', 'Source Han Sans CN');
  } else {
    document.getElementsByTagName('body')[0].style.setProperty('font-family', 'xCurrency Type');
  }

  // 如果是白名单或登录页面，不执行
  if (!allowList.includes(history.location.pathname)) {
    const { token } = history?.location?.query || {};
    if (token && typeof token === 'string') {
      setToken(token);
    }
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      logout: loginOut,
      settings: defaultSettings,
    };
  }
  return {
    fetchUserInfo,
    logout: loginOut,
    settings: defaultSettings,
  };
}

const authHeaderInterceptor = (url: string, options: RequestOptionsInit) => {
  let authHeader = { token: getToken(), 'TAuth-Token': getToken() } as Record<string, string>;
  const queryToken = getQueryString('token');
  if (location.pathname === '/esign/esign-index' && queryToken) {
    authHeader = {
      token: queryToken,
      'TAuth-Token': queryToken,
    };
  }
  return {
    url: `${url}`,
    options: {
      ...options,
      interceptors: true,
      headers: {
        ...authHeader,
        ...options.headers,
      },
    },
  };
};

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器异常或没有进行新建、修改数据的操作。',
  401: '用户没有权限（可以尝试退出重新登录）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

export const request: RequestConfig = {
  prefix: isDev ? '' : defaultSettings.publicPath,
  errorHandler: (error: any) => {
    // 触发umi-request中间件bug
    if (error.response.success === false && error.name === 'BizError') {
      return error.response;
    }

    const { response } = error;
    if (response.status === 403) {
      if (response.url.includes('/api/merchant/info/get')) {
        // history.push('/onboarding');
        history.push('/company-management');
        return;
      }
      if (location.pathname === '/esign/esign-index') {
        return;
      }

      history.push('/exception/403');
      return;
    }

    // filter not set right roles
    if (response.url.includes('/api/backend/manage/api/auth/user/roles')) {
      return;
    }

    let errorText = codeMessage[response.status] || response.statusText;

    // 若返回错误信息带有status字段且不等于1，通知框显示返回的错误信息
    if (error.data && error.data.status && error.data.status !== '1') {
      if (error.data.message) {
        errorText = error.data.message;
      }
    }

    if (
      response &&
      !response.url.endsWith('/api/currentUser') &&
      !response.url.endsWith('/api/tauth/info') &&
      !response.url.endsWith('/api/tauth/token') &&
      !response.url.endsWith('/api/merchant/batch/transfer/check/order') &&
      !response.url.endsWith('/api/merchant/batch/transfer/status') &&
      !response.url.endsWith('/api/merchant/transaction/create') &&
      !response.url.endsWith('/api/merchant/case') &&
      !response.url.endsWith('/api/merchant/case/batch') &&
      !response.url.match(/\/api\/merchant\/transaction\/get\//)
    ) {
      notification.error({
        message: `请求错误 ${response.status}`,
        description: errorText,
      });
    }
    throw error;
  },
  requestInterceptors: [authHeaderInterceptor],
};

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  const pathIconMap = {
    '/wallets': 'icon-YUAN-circle-fill',
    '/batch-transfers': 'icon-right-square-fill',
    '/business-transactions': 'icon-transaction',
    '/personal-transactions': 'icon-transaction',
    '/balance-transactions': 'icon-transaction',
    '/my-customer': 'icon-kehu',
    '/global-payout': 'icon-global',
    '/developer': 'icon-database-fill',
    '/risk-control': 'icon-contacts-fill',
    '/company-management': 'icon-team',
  };

  const menuIconRender = (path: string, defaultDom: React.ReactNode) => {
    const iconClass = pathIconMap[path];
    return (
      <div
        className="xcurrency-custom-menu-item"
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 8,
        }}
      >
        {/* [iconfont 使用指南](https://www.iconfont.cn/help/detail?spm=a313x.7781069.1998910419.d8cf4382a&helptype=code) */}

        {/* font-class 引用 */}
        <i className={`iconfont ${iconClass}`} />
        {defaultDom}
      </div>
    );
  };

  return {
    menuItemRender(item, defaultDom) {
      // console.log('menuItemRender', item, defaultDom, menuProps);
      return item.path && Object.keys(pathIconMap).includes(item.path) ? (
        <Link to={item.path}>{menuIconRender(item.path, defaultDom)}</Link>
      ) : (
        <Link to={item.path ?? '/'}>{defaultDom}</Link>
      );
    },
    subMenuItemRender(item, defaultDom) {
      // console.log('subMenuItemRender', item, defaultDom, menuProps);
      return item.path && Object.keys(pathIconMap).includes(item.path)
        ? menuIconRender(item.path, defaultDom)
        : defaultDom;
    },
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    // footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && !allowList.includes(location.pathname)) {
        if (getLoginFailedTime() > 1) {
          // history.push('/exception/403');
        } else {
          history.push(loginPath);
        }
      }
    },
    // 自定义 403 页面
    unAccessible: <UnAccessible />,
    // 增加一个 loading 的状态
    // childrenRender: (children, props) => {
    //   // if (initialState?.loading) return <PageLoading />;
    //   return (
    //     <>
    //       {children}
    //       {!props.location?.pathname?.includes('/login') && (
    //         <SettingDrawer
    //           enableDarkTheme
    //           settings={initialState?.settings}
    //           onSettingChange={(settings) => {
    //             setInitialState((preInitialState) => ({
    //               ...preInitialState,
    //               settings,
    //             }));
    //           }}
    //         />
    //       )}
    //     </>
    //   );
    // },
    // className: 'center-the-whole-page',

    ...initialState?.settings,
    childrenRender: (children) => {
      return <LayoutWrapper>{children}</LayoutWrapper>;
    },
    logo: isTrataoDomain ? null : (
      <img
        src={logo}
        alt="logo"
        style={{
          height: '21px',
        }}
      />
    ),
    // headerTitleRender(logo, title, props) {
    //   console.log('headerTitleRender', logo, title, props);
    //   return logo;
    // },
    title: false,
    pageTitleRender: (_, defaultPageTitle) => {
      // console.log('props, defaultPageTitle, info', props, defaultPageTitle, info);

      // https://www.jianshu.com/p/ccbf3505c5e6
      let defaultTitle = defaultPageTitle ? `${defaultPageTitle} - ` : '';

      let myTitle =
        localStorage.getItem('umi_locale') === 'zh-CN'
          ? '极简货币合作伙伴平台'
          : 'xCurrency Hubs Partner Platform';

      if (isTrataoDomain) {
        myTitle = '';
        defaultTitle = defaultPageTitle;
      }

      return `${defaultTitle}${myTitle}`;
    },
    siderWidth: 256,
  };
};

// 只在测试环境和生产环境开启
if (!isDev) {
  Sentry.init({
    dsn: 'https://e60df4277b8c1fb50eea68d2d34c0d5a@o4507450661797888.ingest.us.sentry.io/4507450669268992',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: environment as string, // 环境变量
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/masspay-beta.xcurrency\.com\/api/,
      /^https:\/\/lightnet.tratao\.com\/api/,
      /^https:\/\/partner.xcurrency\.com\/api/,
    ],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

fixContext(RouteContext);
fixContext(ConfigProvider.ConfigContext);

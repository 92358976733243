import { useState } from 'react';

export default function useBatchTransferDataSource() {
  const [batchMode, setBatchMode] = useState<string[]>(['CLASSIC']);

  return {
    batchMode,
    setBatchMode,
  };
}

import React, { useEffect } from 'react';

// class UnAccessible extends React.Component {
//   constructor(props: any) {
//     super(props);
//     // 为了相应主 App 路径
//     window.location.href = '/exception/403';
//   }

//   render() {
//     return <div>UnAccessable</div>;
//   }
// }

const UnAccessible = () => {
  useEffect(() => {
    // 为了相应主 App 路径
    window.location.href = '/exception/403';
  }, []);

  return <div>UnAccessable</div>;
};

export default UnAccessible;

import { request } from 'umi';

// 获取远程配置(仅用于本地调试)
export const getConfig = async (platform: string, type: string, f: boolean) => {
  const force = f || false;
  const lastUpdate = `${type}_lastUpdate`;

  const resp = await request('/backend/remote/common/json/type', {
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      platform,
      type,
      explain: 'true',
      force: String(force),
    },
  });

  // console.log('resp message', resp.message);

  if (resp.status === '1') {
    const { data, message: remoteLastUpdate } = resp;
    if (remoteLastUpdate) {
      // 本地无缓存或数据有更新，则强制获取配置
      if (!localStorage.getItem(type) || remoteLastUpdate !== localStorage.getItem(lastUpdate)) {
        localStorage.setItem(lastUpdate, remoteLastUpdate);
        getConfig(platform, type, !force);
      }
    } else {
      // 数据有更新
      localStorage.setItem(type, data ? JSON.stringify(data) : '');
    }
  }
};

export const getPartnerConfigFormServer = async (
  platform: string = 'xtransfer',
  type: string = 'partner_config',
  f: boolean = true,
) => {
  const force = f || false;

  const res = await request('/backend/remote/common/json/type', {
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      platform,
      type,
      explain: 'true',
      force: String(force),
    },
  });

  if (res.status === '1') {
    const { data } = res;
    // 更新数据
    localStorage.setItem(type, data ? JSON.stringify(data) : '');
  }
};

export const getCountryCodeFormServer = async (
  platform: string = 'xtransfer',
  type: string = 'country_code_conf',
  f: boolean = true,
) => {
  const force = f || false;

  const res = await request('/backend/remote/common/json/type', {
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    params: {
      platform,
      type,
      explain: 'true',
      force: String(force),
    },
  });

  if (res.status === '1') {
    const { data } = res;
    // 更新数据
    localStorage.setItem(type, data ? JSON.stringify(data) : '');
  }
};

export const getCache = (type: string) => {
  const text = localStorage.getItem(type);
  return text ? JSON.parse(text) : {};
};

export const getBizConfigCache = () => {
  return getCache('biz_const_conf');
};

export const getBizConfig = (category: string, name?: string) => {
  const conf = getBizConfigCache();
  const cate = conf[`${category}`];
  if (!name) {
    return cate;
  }
  return cate && cate[`${name}`];
};

export const getCountryCode2Cache = () => {
  return getCache('country_code_conf');
};

export const getPartnerConfigCache = () => {
  return getCache('partner_config');
};

export const getPartnerConfig = (category: string, name?: string) => {
  const conf = getPartnerConfigCache();
  const cate = conf[`${category}`];
  if (!name) {
    return cate;
  }
  return cate && cate[`${name}`];
};

export const getCountryCodeConfig = () => {
  const conf = getCountryCode2Cache();
  return conf || [];
};

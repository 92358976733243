export default {
  "menu.wallets": "钱包",
  "menu.wallets.balance": "余额",
  "menu.wallets.balance.detail": "余额详情",
  "menu.wallets.balance.deposit": "充值",
  "menu.wallets.balance.exchange": "换汇",
  "menu.wallets.balance.notifications": "通知",
  "menu.transfers": "转账",
  "menu.transfers.single": "单次转账",
  "menu.transfers.batch": "批量转账",
  "menu.batchTransfers": "批量转账",
  "menu.batchTransfers.personal": "个人汇款转账",
  "menu.batchTransfers.business": "企业汇款转账",
  "menu.personalTransactions.batch": "待转账列表",
  "menu.personalTransactions.batchTransactionList": "批量转账列表",
  "menu.personalTransactions": "个人汇款交易",
  "menu.personalTransactions.balance": "余额交易",
  "menu.personalTransactions.balanceDetail": "商户订单详情",
  "menu.personalTransactions.transfer": "转账交易",
  "menu.personalTransactions.transferDetail": "订单详情",
  "menu.personalTransactions.orderSearch": "订单搜索",
  "menu.businessTransactions.batch": "待转账列表",
  "menu.businessTransactions.batchTransactionList": "批量转账列表",
  "menu.businessTransactions": "企业汇款交易",
  "menu.businessTransactions.balance": "余额交易",
  "menu.businessTransactions.balanceDetail": "商户订单详情",
  "menu.businessTransactions.transfer": "交易清单",
  "menu.businessTransactions.transferDetail": "订单详情",
  "menu.balanceTransactions": "余额交易",
  "menu.balanceTransactions.balance": "余额交易",
  "menu.balanceTransactions.balanceDetail": "商户订单详情",
  "menu.developer": "开发者",
  "menu.developer.xtransfer-payout-api": "xTransfer 代付接口",
  "menu.riskControl.support-docs": "证明材料",
  "menu.myCustomer": "我的客户",
  "menu.myCustomer.cases": "档案管理",
  "menu.myCustomer.personalCase": "档案详情",
  "menu.myCustomer.businessCase": "档案详情",
  "menu.myCustomer.add-personal-customer": "添加客户",
  "menu.myCustomer.add-business-customer": "添加客户",
  "menu.myCustomer.batchUpload": "批量上传",
  "menu.myCustomer.batchUpload.batchRecipientUpload": "客户信息",
  "menu.myCustomer.batchUpload.batchProofMaterialsUpload": "证明材料",
  "menu.riskControl": "风控管理",
  "menu.esign": "网上签约",
  "menu.esign.esign-index": "网上签约",
  "menu.welcome": "欢迎使用",
  "menu.layout": "首页",
  "menu.wallets.balance.balanceEntry": "钱包入口",
  "menu.riskControl.channel-support-docs": "搜索证明材料",
  "menu.riskControl.channel-order-detail": "订单详情",
  "menu.transfers.dispatch": "批量转账",
  "menu.company-management": "公司管理",
  "menu.company-management.info": "公司资料",
  "menu.company-management.add": "添加公司",
  "menu.global-payout": "全球支付",
  "menu.global-payout.order-list": "订单列表",
  "menu.global-payout.order-detail": "订单详情",
  "xch_00001": "监控",
  "xch_00002": "档案",
  "xch_00003": "警报",
  "xch_00004": "仪表板",
  "xch_00005": "分析",
  "xch_00006": "批量筛查",
  "xch_00007": "设置",
  "xch_00008": "eKYC 设置",
  "xch_00009": "放映设置",
  "xch_00010": "配置可匹配字段",
  "xch_00011": "配置筛选首选项",
  "xch_00012": "账户设置",
  "xch_00013": "报告",
  "xch_00014": "全部",
  "xch_00015": "姓名、电话号码、身份证号码",
  "xch_00016": "档案名称",
  "xch_00017": "国家/地区",
  "xch_00018": "部分",
  "xch_00019": "状态",
  "xch_00020": "操作",
  "xch_00021": "编辑",
  "xch_00022": "下载报告",
  "xch_00023": "{0}/页",
  "xch_00024": "前往",
  "xch_00025": "添加过滤视图",
  "xch_00026": "编辑过滤器视图",
  "xch_00027": "创建时间",
  "xch_00028": "档案 ID",
  "xch_00029": "更新时间",
  "xch_00030": "姓名",
  "xch_00031": "性别",
  "xch_00032": "国家",
  "xch_00033": "出生日期",
  "xch_00034": "关系",
  "xch_00035": "上次筛查时间",
  "xch_00036": "关联",
  "xch_00037": "协会信息",
  "xch_00038": "阶段",
  "xch_00039": "开户",
  "xch_00040": "自动筛查",
  "xch_00041": "审查",
  "xch_00042": "通过",
  "xch_00043": "资料",
  "xch_00044": "审查",
  "xch_00045": "评论",
  "xch_00046": "证明详情",
  "xch_00047": "不限",
  "xch_00048": "添加",
  "xch_00049": "删除",
  "xch_00050": "确定",
  "xch_00051": "评论详情",
  "xch_00052": "保存",
  "xch_00053": "匹配",
  "xch_00054": "警报时间",
  "xch_00055": "类别",
  "xch_00056": "重新开启",
  "xch_00057": "资料",
  "xch_00058": "档案",
  "xch_00059": "钱包",
  "xch_00060": "余额",
  "xch_00061": "转账",
  "xch_00062": "顾客",
  "xch_00063": "交易",
  "xch_00064": "帮助",
  "xch_00065": "xCurrency 实时报价",
  "xch_00066": "通知",
  "xch_00067": "查看更多",
  "xch_00068": "单次转账",
  "xch_00069": "批量转账",
  "xch_00070": "上传转账列表",
  "xch_00071": "确认转账",
  "xch_00072": "支付转账",
  "xch_00073": "上传您的转账文件，或先下载模板并填充转账数据后再上传。",
  "xch_00074": "下载模板",
  "xch_00075": "点击或拖拽文件至此区域即可上传",
  "xch_00076": "支持单个上传。请不要上传跟批量转账不相关的文件。",
  "xch_00077": "您的批量转账文件正在上传和解析中，请耐心等待。",
  "xch_00078": "准备您的转账中",
  "xch_00079": "准备中，如果有很多转账，可能需要一点时间。您可以关闭此页面并稍后再回来。",
  "xch_00080": "所有转账均创建成功",
  "xch_00081": "恭喜，所有转账均已成功创建。您可以继续完成付款。",
  "xch_00082": "上传概览",
  "xch_00083": "成功创建的转账",
  "xch_00084": "创建失败的转账",
  "xch_00085": "继续",
  "xch_00086": "取消",
  "xch_00087": "{0}笔转账未能处理",
  "xch_00088": "有{0}笔转账创建失败，您可以选择忽略并继续传输，也可以选择修改和重新上传。",
  "xch_00089": "失败的转账详情",
  "xch_00090": "下载失败的转账",
  "xch_00091": "行数",
  "xch_00092": "商户订单号",
  "xch_00093": "客户名称",
  "xch_00094": "错误信息",
  "xch_00095": "支付",
  "xch_00096": "{0} 笔转账已经可以支付",
  "xch_00097": "恭喜，{0} 笔转账均已成功创建。您可以继续完成付款。",
  "xch_00098": "支付详情",
  "xch_00099": "原币种",
  "xch_00100": "收款币种",
  "xch_00101": "收款币种总金额",
  "xch_00102": "汇率",
  "xch_00103": "付款金额",
  "xch_00104": "手续费",
  "xch_00105": "总计 {0} 付款金额",
  "xch_00106": "付款前余额",
  "xch_00107": "付款后余额",
  "xch_00108": "{0}笔转账成功",
  "xch_00109": "{0}笔转账失败",
  "xch_00110": "重新批量上传",
  "xch_00111": "忽略创建失败的转账并继续",
  "xch_00112": "确认",
  "xch_00113": "确认付款",
  "xch_00114": "单击“确认”后，批量转账将开始处理。",
  "xch_00115": "您的余额不足",
  "xch_00116": "请先充值资金到您的钱包",
  "xch_00117": "您的批量转账正在处理",
  "xch_00118": "这通常需要一些时间，请耐心等待。",
  "xch_00119": "客户的身份证明文件有效期过期，请与客户联系补充新的身份证明文件有效期。",
  "xch_00120": "付款人的国籍与身份证明文件的发行国家问题不符。",
  "xch_00121": "身份证明文件的发行国家必须是付款人的居住国。",
  "xch_00122": "客户年龄不符合风控要求。",
  "xch_00123": "付款人不能是公司。",
  "xch_00124": "付款人进入黑名单，不允许进行交易。",
  "xch_00125": "收款人进入黑名单，不允许进行交易。建议更换其他收款人。",
  "xch_00126": "收款人银行卡信息校验失败。可能是客户银行卡信息填写错误。请联系客户确认银行卡信息（姓名、卡号、证件号等）或更换其他银行卡或收款人后再试。",
  "xch_00127": "收款人银行卡不支持收款，请联系客户更换其他银行卡或收款人后再试。",
  "xch_00128": "收款人转账额度超过限制。建议客户过一段时间后再试。",
  "xch_00129": "收款人银行卡状态异常，不支持收款。请联系客户更换其他银行卡或收款人后再试。",
  "xch_00130": "您只能上传基于模板填充了转账数据的文件",
  "xch_00131": "同一个批次只能上传一个文件",
  "xch_00132": "上传模板文件的版本太旧了，请重新下载并填写转账数据后再次上传。",
  "xch_00133": "批处理转账已经完成，但是以下转账失败了。您可以检查下面所列的失败原因。",
  "xch_00134": "恭喜您，批量转账已经全部完成。",
  "xch_00135": "您有批量转账处于待付款状态，请继续完成付款或取消付款后再上传新批次。",
  "xch_00136": "您有批量转账处于转账处理状态，请耐心等待转账完成再上传新批次。",
  "xch_00137": "开发者",
  "xch_00138": "xTransfer 代付接口",
  "xch_00139": "一键接入 {1}扩展{0}",
  "xch_00140": "您的业务到中国",
  "xch_00141": "7×24 小时即时转账",
  "xch_00142": "市场领先的汇率",
  "xch_00143": "支持中国几乎所有银行",
  "xch_00144": "开始整合",
  "xch_00145": "搜索",
  "xch_00146": "开始日期",
  "xch_00147": "结束日期",
  "xch_00148": "订单或姓名",
  "xch_00149": "重置",
  "xch_00150": "导出",
  "xch_00151": "收款人姓名",
  "xch_00152": "货币对",
  "xch_00153": "汇款金额",
  "xch_00154": "手续费",
  "xch_00155": "汇率",
  "xch_00156": "抱歉，您的转账数量超过了我们支持的最多 {0} 条。请删除一些转账，然后再次上传。",
  "xch_00157": "抱歉，我们没有在您上传的文件中读取到任何转账数据。请您确保上传的文件正确无误后再上传。",
  "xch_00158": "收款金额",
  "xch_00159": "待下发",
  "xch_00160": "成功",
  "xch_00161": "失败",
  "xch_00162": "已取消",
  "xch_00163": "详情",
  "xch_00164": "关键词",
  "xch_00165": "总共 {0} 件商品",
  "xch_00166": "我们于 {0} 更新了新的模板，请先下载新的模板再填充转账数据和上传，我们才能正确解析您的批量转账数据文件。",
  "xch_00167": "订单详情",
  "xch_00168": "模板已更新",
  "xch_00169": "支付金额",
  "xch_00170": "汇率",
  "xch_00171": "付款人信息",
  "xch_00172": "基本信息",
  "xch_00173": "身份信息",
  "xch_00174": "类型",
  "xch_00175": "发行国家",
  "xch_00176": "号码",
  "xch_00177": "到期日期",
  "xch_00178": "地址",
  "xch_00179": "详细地址",
  "xch_00180": "城市",
  "xch_00181": "地区或州",
  "xch_00182": "邮政编码",
  "xch_00183": "居住国家",
  "xch_00184": "关联",
  "xch_00185": "资金来源",
  "xch_00186": "转账目的",
  "xch_00187": "收款人信息",
  "xch_00188": "银行帐号",
  "xch_00189": "身份证",
  "xch_00190": "国家",
  "xch_00191": "州/省",
  "xch_00192": "县辖区",
  "xch_00193": "国籍",
  "xch_00194": "职业",
  "xch_00195": "有效期",
  "xch_00196": "收款人在银行预留的身份证信息已过期，无法收取该笔资金。建议与客户联系更新身份证信息或更换其他银行卡或收款人后重试。",
  "xch_00197": "收款人身份证号码格式不正确，请与客户联系以确认身份证号码信息或更换其他收款人后再试。",
  "xch_00198": "收款人的银行卡是II或III类个人银行结算帐户，超过每日限额。请与客户联系明天再试，或更换其他银行卡或收款人后再试。注意：II类帐户的每日限额通常为10,000，III类帐户的每日限额为5,000）。",
  "xch_00199": "结汇失败，跨币种转账模式，结算时间为北京时间工作日周一至周五 9:30-16:00，UTC+08:00。",
  "xch_00200": "银行报告您的转账信息格式不正确，转账失败了。请确认此笔转账信息或联系我们以获得帮助。",
  "xch_00201": "该笔转账异常，请联系我们以获得帮助。",
  "xch_00202": "银行报告收款人这笔转账的生日格式不正确。请确认此笔转账的信息或与我们联系以获得帮助。",
  "xch_00203": "银行报告此笔转账的收款人的姓名不匹配。请与客户联系确认信息或更换其他收款人后重试。",
  "xch_00204": "银行报告此笔转账的收款人因在线验证失败而无法收取资金，请与客户联系以确认信息或更改其他收款人后重试。",
  "xch_00205": "余额不足，转账失败。请确认您钱包中的余额或联系我们寻求帮助。",
  "xch_00206": "银行报告此笔转账的付款人的银行账号不能为空。请确认此笔转账的信息或与我们联系以获得帮助。",
  "xch_00207": "银行报告此笔转账的付款人的常住国不能为空。请确认此笔转账的信息或与我们联系以获得帮助。",
  "xch_00208": "暂时无法支付",
  "xch_00209": "因为未能以「跨币种交易模式」（结算时间是工作日星期一至星期五 9：30-16：00，UTC+08：00）执行此批量转账而支付失败。请在结算时间内再尝试发起支付或使用「同币种交易模式」（结算时间是 7x 24 小时）。有任何疑问可以联系我们。",
  "xch_00210": "此笔转账的{0}不能为空。请确认此笔转账信息或与我们联系以寻求帮助。",
  "xch_00211": "收款人的银行卡号不是银联卡号，因此不支持转账。请联系客户更换为银行卡信息或其他收款人后重试。注意：目前暂不支持银行账户（比如：存折账户）",
  "xch_00212": "此笔转账的商户订单号已经存在于历史的转账列表中，请确认信息是否正确。",
  "xch_00213": "「付款金额」和「收款金额」两个必填其一。",
  "xch_00214": "收款人的地址的县或者区无法识别或者填写错误。",
  "xch_00215": "显示设置",
  "xch_00216": "日",
  "xch_00217": "周",
  "xch_00218": "月",
  "xch_00219": "付款人或收款人的帐户需要进一步的人工审查，通常我们很快审查，如果这种状态持续存在，请与我们联系以寻求帮助。",
  "xch_00220": "付款人或收款人的手机号码已被他人绑定，请与我们联系以获得帮助。",
  "xch_00221": "付款人或收款人的身份信息与他人存在重复，需要进一步确认。通常，我们很快审查。如果这种状态持续存在，请与我们联系以寻求帮助。",
  "xch_00222": "您填写的「 {0}」格式不正确。请确认您是按照要求填写。如有任何疑问，您可以查看帮助文档或联系我们以获得帮助。",
  "xch_00223": "抱歉，全部转账都生成失败。",
  "xch_00224": "您可以修改并重新上传。",
  "xch_00225": "只有当您点击「确认付款」后，我们才将为您锁定汇率。",
  "xch_00226": "您填写的「 {0}」的格式不正确，请确认您是按照日期格式要求进行填写。如有疑问可以查看帮助文档或联系我们以获得帮助。",
  "xch_00227": "您填写的「 {0}」的格式不正确，请确认您填写的信息里没有包含数字以外的字符，如有疑问可以查看帮助文档或联系我们以获得帮助。",
  "xch_00228": "您填写的「 {0}」的币种暂不支持，如有疑问可以联系我们以获得帮助。",
  "xch_00229": "您填写的「 {0}」的金额超过最大限制，如有疑问可以联系我们以获得帮助。",
  "xch_00230": "在此模板中，收款人的姓名必须用中文。如果您有任何疑问，可以检查帮助文档或与我们联系以寻求帮助。",
  "xch_00231": "账户余额",
  "xch_00232": "账户信息",
  "xch_00233": "账户名",
  "xch_00234": "公司",
  "xch_00235": "珠海踹桃科技有限公司",
  "xch_00236": "刷新",
  "xch_00237": "当前展示汇率仅供参考，5分钟内有效。请在交易后确认实际汇率。",
  "xch_00238": "工作日下午 6 点 30 分更新",
  "xch_00239": "天前",
  "xch_00240": "天前",
  "xch_00241": "小时前",
  "xch_00242": "小时前",
  "xch_00243": "分钟前",
  "xch_00244": "分钟前",
  "xch_00245": "总金额",
  "xch_00246": "全部转账失败",
  "xch_00247": "您可以检查下面所列的失败原因。",
  "xch_00248": "导出 {0} 个项目",
  "xch_00249": "导出 {0} 个项目",
  "xch_00250": "模板",
  "xch_00251": "自定义数据列",
  "xch_00252": "可用栏",
  "xch_00253": "显示栏",
  "xch_00254": "支付完成时间",
  "xch_00255": "因为网络波动问题上传失败，请您重新上传一次。",
  "xch_00256": "退出登录",
  "xch_00257": "授权失败，请重新登录",
  "xch_00258": "重新登录",
  "xch_00259": "全部数据列",
  "xch_00260": "查看详情",
  "xch_00261": "付款人的身份证明文件不能是中国居民身份证。",
  "xch_00262": "充值到余额",
  "xch_00263": "确认金额",
  "xch_00264": "跟踪订单",
  "xch_00265": "填写充值信息",
  "xch_00266": "充值金额",
  "xch_00267": "限额",
  "xch_00268": "充值类型",
  "xch_00269": "支持「低成本换汇」",
  "xch_00270": "支持「快速换汇」",
  "xch_00271": "汇率：",
  "xch_00272": "交割时效：",
  "xch_00273": "换汇交易可用时间：",
  "xch_00274": "更好的汇率",
  "xch_00275": "即时交割",
  "xch_00276": "工作日",
  "xch_00277": "请通过电汇向以下账户充值",
  "xch_00278": "账户信息",
  "xch_00279": "付款明细",
  "xch_00280": "币种",
  "xch_00281": "银行名称",
  "xch_00282": "SWIFT 代码",
  "xch_00283": "银行地址",
  "xch_00284": "收款人名称",
  "xch_00285": "帐号",
  "xch_00286": "收款人地址",
  "xch_00287": "付款备注",
  "xch_00288": "您充值",
  "xch_00289": "您到账",
  "xch_00290": "到账时间",
  "xch_00291": "{0} 天内",
  "xch_00292": "您创建了充值订单",
  "xch_00293": "按照提示完成充值",
  "xch_00294": "您的资金正在转账到我们银行账户途中",
  "xch_00295": "我们已收到您的{0}",
  "xch_00296": "我们将您的 {0} 添加到您的余额中",
  "xch_00297": "我们还没有付款",
  "xch_00298": "我们已付款",
  "xch_00299": "取消充值",
  "xch_00300": "余额交易",
  "xch_00301": "{0}余额",
  "xch_00302": "换汇",
  "xch_00303": "金额",
  "xch_00304": "换汇金额",
  "xch_00305": "可用余额：",
  "xch_00306": "提交订单",
  "xch_00307": "选择全部",
  "xch_00308": "低成本模式",
  "xch_00309": "快速模式",
  "xch_00310": "何时换汇",
  "xch_00311": "现在",
  "xch_00312": "在指定的汇率（即将推出）",
  "xch_00313": "在指定的时间（即将推出）",
  "xch_00314": "汇率趋势",
  "xch_00315": "即将推出",
  "xch_00316": "确认订单",
  "xch_00317": "换汇时间概览",
  "xch_00318": "换汇模式",
  "xch_00319": "收款金额",
  "xch_00320": "参考汇率",
  "xch_00321": "换汇后的 {0} 余额",
  "xch_00322": "用户换汇的 {0} 金额",
  "xch_00323": "换汇金额概览",
  "xch_00324": "取消换汇",
  "xch_00325": "处理中",
  "xch_00326": "限额 {0}〜{1} {2}",
  "xch_00327": "可用",
  "xch_00328": "全部",
  "xch_00329": "充值",
  "xch_00330": "换汇",
  "xch_00331": "电汇转账过程中银行及其代理行可能会收取手续费。这取决于您在电汇时候选择的手续费承担方式。更多详细信息可以联系您的开户行进行咨询。充值金额以我们收到的实际金额为准。",
  "xch_00332": "支持低成本换汇",
  "xch_00333": "支持快速换汇",
  "xch_00334": "汇款充值到账速度和汇出行的操作时间以及选择的汇款路径有关，建议您提供汇款路径给到我们客户成功经理以便我们更好为您提供建议来提高充值到账速度。",
  "xch_00335": "成功",
  "xch_00336": "进行中",
  "xch_00337": "订单编号",
  "xch_00338": "交易时间",
  "xch_00339": "金额 ({0})",
  "xch_00340": "订单编号",
  "xch_00341": "筛选",
  "xch_00342": "{0}余额",
  "xch_00343": "余额详情",
  "xch_00344": "到达时间",
  "xch_00345": "打开（需要操作）",
  "xch_00346": "入职",
  "xch_00347": "筛选",
  "xch_00348": "已确认（审核中）",
  "xch_00349": "已清除",
  "xch_00350": "已确认（风险已降低）",
  "xch_00351": "已确认（无风险）",
  "xch_00352": "已确认（已阻止）",
  "xch_00353": "自己",
  "xch_00354": "家长",
  "xch_00355": "配偶",
  "xch_00356": "孩子们",
  "xch_00357": "兄弟",
  "xch_00358": "祖父母",
  "xch_00359": "孙子",
  "xch_00360": "姓名、电话号码、身份证号码",
  "xch_00361": "档案详情",
  "xch_00362": "男",
  "xch_00363": "相关警报",
  "xch_00364": "协会信息",
  "xch_00365": "上传",
  "xch_00366": "历史",
  "xch_00367": "姓",
  "xch_00368": "名",
  "xch_00369": "姓氏（拼音）",
  "xch_00370": "名字（拼音）",
  "xch_00371": "街道",
  "xch_00372": "详细地址",
  "xch_00373": "邮政编码",
  "xch_00374": "证件号码",
  "xch_00375": "确认兑换？您的订单将立即交换。",
  "xch_00376": "汇率已经改变",
  "xch_00377": "由于国际外汇市场实时波动，汇率也发生变化。请再次确认汇率报价。",
  "xch_00378": "失效日期",
  "xch_00379": "签发日期",
  "xch_00380": "查看证书",
  "xch_00381": "您创建了一个换汇订单",
  "xch_00382": "等待满足换汇条件",
  "xch_00383": "我们已经交换了您的{0}",
  "xch_00384": "我们将您的 {0} 添加到您的余额中",
  "xch_00385": "交易汇率",
  "xch_00386": "关联交易",
  "xch_00387": "我们已经全面升级为同币种（CNY）交易模式，支持7×24小时即时转账。批量转账前，请先将外币兑换至人民币钱包。如果您有任何疑问，可以联系我们的客户成功经理。",
  "xch_00388": "知道了",
  "xch_00389": "余额交易",
  "xch_00390": "转账交易",
  "xch_00391": "通知",
  "xch_00392": "取消此 {0} 订单吗？",
  "xch_00393": "此{0}订单将不会处理，您可以稍后再次发起。",
  "xch_00394": "确认取消",
  "xch_00395": "不用了",
  "xch_00396": "我们收到您的{0}",
  "xch_00397": "请将 {0} 转入我们提供的银行详细信息以完成存款。",
  "xch_00398": "您的银行可能需要一些时间才能将其交给我们。到货后我们会通知您。",
  "xch_00399": "我们正在将您的 {0} 添加到您的余额中",
  "xch_00400": "我们已将您的 {0} 添加到您的余额中",
  "xch_00401": "您取消了订单",
  "xch_00402": "您已完成存款",
  "xch_00403": "交易汇率",
  "xch_00404": "当前暂不可换汇",
  "xch_00405": "{0} 时可以换汇，请在这个时间段内重试。",
  "xch_00406": "取消订单",
  "xch_00407": "不",
  "xch_00408": "{0}是必填项",
  "xch_00409": "充值后的 {0} 余额",
  "xch_00410": "没有更多了",
  "xch_00411": "即时",
  "xch_00412": "通知列表",
  "xch_00413": "我们正在交换您的{0}",
  "xch_00414": "新的",
  "xch_00415": "已添加此汇率",
  "xch_00416": "请选择",
  "xch_00417": "低成本模式",
  "xch_00418": "您创建了批量转移订单",
  "xch_00419": "您的转账订单正在等待付款",
  "xch_00420": "您已支付转账单",
  "xch_00420": "您已支付转账单",
  "xch_00421": "批量转账已完成",
  "xch_00422": "您的批量传输正在处理中",
  "xch_00423": "所有转账均未能处理",
  "xch_00424": "我们处理您的批量转移",
  "xch_00425": "等待满足兑换条件",
  "xch_00426": "已满足兑换条件",
  "xch_00427": "根据境内银行和监管要求，您需要在后台提供付款人证件和亲属关系证明，如果您有任何疑问，可以联系我们的客户经理。",
  "xch_00428": "根据境内银行和监管要求，您需要在后台提供付款人的证件和收入证明，如果您有任何疑问，可以联系我们的客户经理。",
  "xch_00429": "被拒绝",
  "xch_00430": "更改密码",
  "xch_00431": "收款人的银行卡超过每日限额。请与客户联系明天再试，或更换其他银行卡或收款人后再试。",
  "xch_00432": "付款人或收款人身份验证失败。请确认您提交的身份信息或证明材料是否正确。如果您有任何疑问，可以联系我们的客户经理。",
  "xch_00433": "待下发",
  "xch_00434": "待审核",
  "xch_00435": "待补充材料",
  "xch_00436": "是否立即下发?",
  "xch_00437": "交易材料",
  "xch_00438": "根据合作银行的反洗钱要求，以确保不断使用我们的服务 请在48小时内提交收款人",
  "xch_00439": "和付款人",
  "xch_00440": "的证明。",
  "xch_00441": "关系证明",
  "xch_00442": "收款人是付款人的",
  "xch_00443": "附加材料",
  "xch_00444": "请选择要认证的证件类型",
  "xch_00445": "付款人证件",
  "xch_00446": "结婚证书",
  "xch_00447": "亲属关系承诺函",
  "xch_00448": "户口本",
  "xch_00449": "赡养公证书",
  "xch_00450": "出生证明",
  "xch_00451": "雇佣合同",
  "xch_00452": "收入证明",
  "xch_00453": "在职证明",
  "xch_00454": "工资流水",
  "xch_00455": "纳税证明",
  "xch_00456": "兄弟姐妹",
  "xch_00457": "子孙女、外子孙女",
  "xch_00458": "风控管理",
  "xch_00459": "证明材料",
  "xch_00460": "失败",
  "xch_00461": "商户订单号或证件号或名字",
  "xch_00462": "付款人",
  "xch_00463": "付款人证件号码",
  "xch_00464": "收款人",
  "xch_00465": "请上传",
  "xch_00466": "模板",
  "xch_00467": "示例如下",
  "xch_00468": "交易不处于待下发状态，不能下发",
  "xch_00469": "下发失败",
  "xch_00470": "收款人证件号码",
  "xch_00471": "材料审核中",
  "xch_00472": "关系不能为其他人，如需补充材料，请联系客户经理",
  "xch_00473": "在线签名",
  "xch_00474": "提交前请签名。",
  "xch_00475": "网上签约",
  "xch_00476": "待完善",
  "xch_00477": "待提交",
  "xch_00478": "链接已失效，请联系客服重新获取",
  "xch_00479": "刷新亲属承诺函的状态",
  "xch_00480": "刷新",
  "xch_00481": "XC模式",
  "xch_00482": "欢迎使用",
  "xch_00483": "首页",
  "xch_00484": "钱包入口",
  "xch_00485": "搜索证明材料",
  "xch_00486": "商户订单详情",
  "xch_00487": "支持「XC换汇」",
  "xch_00488": "客户管理",
  "xch_00489": "客户名单",
  "xch_00490": "汇率配置",
  "xch_00491": "订单管理",
  "xch_00492": "充值订单",
  "xch_00493": "换汇订单",
  "xch_00494": "资金管理",
  "xch_00495": "资金池总览",
  "xch_00496": "客户余额",
  "xch_00497": "客户列表入口",
  "xch_00498": "余额详情",
  "xch_00499": "支持XC换汇",
  "xch_00500": "选择模式",
  "xch_00501": "购买XCNH",
  "xch_00502": "余额管理",
  "xch_00503": "钱包管理",
  "xch_00504": "订单详情",
  "xch_00505": "选择模板",
  "xch_00506": "上传您的文件，或选择要下载的模板，然后开始。",
  "xch_00507": "选择模板",
  "xch_00508": "选择要下载的模板",
  "xch_00509": "我们支持不同的文件模板来帮助您开始批量传输。使用最适合您的一种。",
  "xch_00510": "如果您知道收款人的银行详细信息，您可以将资金直接转入他们的银行账户。",
  "xch_00511": "收款人收款信息：",
  "xch_00512": "收款人全名（中文）",
  "xch_00513": "收款人性别",
  "xch_00514": "收款人国籍",
  "xch_00515": "收款人职业",
  "xch_00516": "收款人邮政编码 ",
  "xch_00517": "收款人省份",
  "xch_00518": "收款人城市",
  "xch_00519": "收款人所在州或地区",
  "xch_00520": "收款人详细地址",
  "xch_00521": "收款人电话号码",
  "xch_00522": "收款人身份证号码",
  "xch_00523": "身份证有效期始",
  "xch_00524": "身份证有效期末",
  "xch_00525": "收款人银行账号",
  "xch_00526": "如果您只知道收款人的银行详细信息，您可以直接将钱转入他们的银行账户。",
  "xch_00527": "收款人全名（中文）",
  "xch_00528": "下载",
  "xch_00529": "去交易",
  "xch_00531": "费用:  {0}  / 每笔",
  "xch_00533": "切换模式",
  "xch_00534": "选择模式",
  "xch_00535": "经典模式",
  "xch_00536": "简洁模式",
  "xch_00537": " ",
  "xch_00538": "网络错误，请稍后刷新重试",
  "xch_00539": "支持经典模式转账",
  "xch_00540": "支持简洁模式转账",
  "xch_00541": "返回余额页面",
  "xch_00542": "交易模式",
  "xch_00543": "已创建",
  "xch_00544": "待入账",
  "xch_00545": "已完成",
  "xch_00546": "已入账",
  "xch_00547": "已取消",
  "xch_00548": "已换汇",
  "xch_00549": "客户资金池总览",
  "xch_00550": "系统后备资金不足，正在准备中，请稍后",
  "xch_00551": "交易速度较快，但汇率不是最佳的",
  "xch_00552": "汇率较佳，但是交易速度不是最快的",
  "xch_00553": "交易速度和汇率都是最佳的",
  "xch_00554": "消息管理",
  "xch_00555": "消息列表",
  "xch_00556": "数据分析",
  "xch_00557": "币种分析",
  "xch_00558": "交易分析",
  "xch_00559": "汇款情况",
  "xch_00560": "到账时长",
  "xch_00561": "欢迎",
  "xch_00562": "客户余额",
  "xch_00563": "交易模式解释",
  "xch_00564": "客户名称",
  "xch_00565": "充值币种",
  "xch_00566": "交易币种",
  "xch_00567": "未配置汇率",
  "xch_00568": "邮箱",
  "xch_00569": "账户状态",
  "xch_00570": "操作",
  "xch_00571": "编辑",
  "xch_00572": "汇款记录",
  "xch_00573": "请输入客户姓名",
  "xch_00574": "请输入{0}",
  "xch_00575": "商户号",
  "xch_00576": "商户编码",
  "xch_00577": "请选择{0}",
  "xch_00578": "备注",
  "xch_00579": "进展率",
  "xch_00580": "可能需要 {0} 秒",
  "xch_00581": "操作成功",
  "xch_00582": "操作失败",
  "xch_00583": "操作",
  "xch_00584": "汇款记录",
  "xch_00585": "原始货币",
  "xch_00586": "目标货币",
  "xch_00587": "基准汇率源",
  "xch_00588": "报价汇率",
  "xch_00589": "目标货币兑换量",
  "xch_00590": "汇差",
  "xch_00591": "基准汇率",
  "xch_00592": "启用状态",
  "xch_00593": "创建",
  "xch_00594": "汇率报价",
  "xch_00595": "姓名",
  "xch_00596": "汇差 %",
  "xch_00598": "货币",
  "xch_00599": "客户",
  "xch_00601": "只查询订单数量",
  "xch_00602": "只查询交易量",
  "xch_00603": "查询订单数量和交易量",
  "xch_00604": "天",
  "xch_00605": "星期",
  "xch_00606": "月",
  "xch_00607": "年",
  "xch_00608": "交易聚合分析",
  "xch_00609": "总交易额",
  "xch_00610": "日期",
  "xch_00611": "订单数量",
  "xch_00612": "订单金额",
  "xch_00613": "入账金额",
  "xch_00614": "转账凭证",
  "xch_00615": "入账",
  "xch_00616": "实际入账金额",
  "xch_00617": "如何分配",
  "xch_00618": "完成",
  "xch_00619": "调拨中",
  "xch_00620": "支持经典模式下发",
  "xch_00621": "支持简洁模式下发",
  "xch_00622": "工作台",
  "xch_00623": "转账中",
  "xch_00624": "您的代理尚未配置该货币对的汇率，请联系您的代理。",
  "xch_00625": "您有订单尚未处理，请及时处理！",
  "xch_00626": "申请XC",
  "xch_00627": "订单总数",
  "xch_00628": "转账完成数",
  "xch_00629": "进度",
  "xch_00630": "批量转账列表",
  "xch_00631": "关闭",
  "xch_00632": "批次号",
  "xch_00633": "系统维护中，暂时无法完成自主交易，请联系您的客户经理进行兑换交易",
  "xch_00634": "取消选择",
  "xch_00635": "批量取消",
  "xch_00636": "您确定要取消所选订单吗？",
  "xch_00637": "不再提醒",
  "xch_00638": "转账完成",
  "xch_00639": "添加过滤视图",
  "xch_00640": "编辑过滤视图",
  "xch_00641": "删除过滤视图",
  "xch_00642": "添加客户",
  "xch_00643": "客户编号",
  "xch_00644": "业务类型",
  "xch_00645": "提升额度",
  "xch_00646": "查看",
  "xch_00647": "英文姓氏",
  "xch_00648": "姓氏",
  "xch_00649": "英文名",
  "xch_00650": "名",
  "xch_00651": "出生日期",
  "xch_00652": "证件号码",
  "xch_00653": "证件过期时间",
  "xch_00654": "女",
  "xch_00655": "背面",
  "xch_00656": "正面",
  "xch_00657": "侧面",
  "xch_00658": "确认（审查）",
  "xch_00659": "打开（需要行动）",
  "xch_00660": "确认（低风险）",
  "xch_00661": "被拒绝",
  "xch_00662": "拒绝类型",
  "xch_00663": "风险原因",
  "xch_00664": "视图名字",
  "xch_00665": "此项为必填项",
  "xch_00666": "业务地区",
  "xch_00667": "删除选项卡",
  "xch_00668": "您确定要删除此选项卡吗？",
  "xch_00669": "预览",
  "xch_00670": "审查通过",
  "xch_00671": "审查中",
  "xch_00672": "资金证明材料",
  "xch_00673": "关系证明材料",
  "xch_00674": "选择要提交的证明材料",
  "xch_00675": "应包含雇员姓名、工作单位、收入、工作期限等基本雇佣信息，并加盖雇主印章",
  "xch_00676": "它应包含基本就业信息，例如雇员姓名、雇主、收入和就业期限",
  "xch_00677": "上传公司提供的收入证明",
  "xch_00678": "公司提供的在职证明",
  "xch_00679": "上传公司提供的在职证明",
  "xch_00680": "应包含基本雇佣信息，例如雇员姓名、雇主和雇佣期限",
  "xch_00681": "应包含雇主或工资支付附言以及雇员姓名",
  "xch_00682": "最近的工资收入银行对账单",
  "xch_00683": "上传近期工资收入银行对账单",
  "xch_00684": "应包含员工姓名、过去一年应税收入总额等基本信息",
  "xch_00685": "纳税证明",
  "xch_00686": "上传纳税证明",
  "xch_00687": "请提供与收款人的亲属关系承诺函，以确保不断使用我们的服务。",
  "xch_00688": "请提供您的户口本信息，以确保持续使用我们的服务。",
  "xch_00689": "请提供赡养公证书作为证明，以确保持续使用我们的服务。",
  "xch_00690": "请提供您的结婚证信息，以确保继续使用我们的服务。",
  "xch_00691": "请提供收款亲属的出生证明，以确保继续使用我们的服务。",
  "xch_00692": "后退",
  "xch_00693": "请检查是否填写完整",
  "xch_00694": "上传证件并填写信息",
  "xch_00695": "获取档案过滤器视图数据失败",
  "xch_00696": "删除失败,无此记录,请刷新",
  "xch_00697": "名称重复，请使用其他名称重新提交",
  "xch_00698": "保存过滤器视图失败",
  "xch_00699": "银行卡号",
  "xch_00700": "道琼斯验证",
  "xch_00701": "同意并继续 ",
  "xch_00702": "您的个人信息将被加密存储 ",
  "xch_00703": "快捷身份认证",
  "xch_00704": "并提升额度",
  "xch_00705": "我们很乐意帮助您的客户提升汇款限额。我们需要进一步 的证明材料，以便您的客户能够将更多资金汇回家中",
  "xch_00706": "上传并识别 {0} 作为身份证明",
  "xch_00707": "身份证或护照",
  "xch_00708": "补充 {0} 等个人资料",
  "xch_00709": "职业和住址",
  "xch_00710": "全名",
  "xch_00711": "身份证明详情",
  "xch_00712": "收集日期",
  "xch_00713": "收集材料",
  "xch_00714": "待转账列表",
  "xch_00715": "批量转账列表",
  "xch_00716": "我的客户",
  "xch_00717": "档案管理",
  "xch_00718": "档案详情",
  "xch_00719": "添加客户",
  "xch_00720": "支持「企业汇款换汇」",
  "xch_00721": "货贸模式",
  "xch_00722": "支持企业汇款转账",
  "xch_00723": "交易场景",
  "xch_00724": "个人汇款",
  "xch_00725": "企业汇款",
  "xch_00726": "企业汇款",
  "xch_00727": "尽请期待",
  "xch_00728": "个人汇款转账",
  "xch_00729": "企业汇款转账",
  "xch_00730": "个人汇款交易",
  "xch_00731": "企业汇款交易",
  "xch_00732": "付款企业名称",
  "xch_00733": "款项性质",
  "xch_00734": "收款企业名称",
  "xch_00735": "实际收款账户名称",
  "xch_00736": "补充材料",
  "xch_00737": "应包括交易双方名称、地址，名称、材质、数量、型号、单价、总价、交易日期、交易双方签名或者盖章、交易订单号、发票号等信息。",
  "xch_00738": "PI/PO",
  "xch_00739": "上传形式发票/采购订单",
  "xch_00740": "订单号",
  "xch_00741": "订单证明材料",
  "xch_00742": "上传文件",
  "xch_00743": "确认信息",
  "xch_00744": "创建订单",
  "xch_00745": "准备创建 {0} 笔订单",
  "xch_00746": "恭喜，所有订单均已成功验证。您可以继续创建订单。",
  "xch_00747": "恭喜，{0} 笔订单已成功校验。您可以继续创建订单。",
  "xch_00748": "点击“确认”后，订单将被创建。",
  "xch_00749": "确认订单创建",
  "xch_00750": "所有订单均创建成功",
  "xch_00751": "恭喜，所有订单已成功创建，您可以继续完成付款。",
  "xch_00752": "前往补充材料",
  "xch_00753": "上传概览",
  "xch_00754": "成功校验的订单",
  "xch_00755": "校验失败的订单",
  "xch_00756": "交易清单",
  "xch_00757": "交易信息",
  "xch_00758": "物流单号",
  "xch_00759": "物流公司",
  "xch_00760": "材料信息",
  "xch_00761": "交易合约",
  "xch_00762": "企业中文名",
  "xch_00763": "收款账户名称",
  "xch_00764": "收款银行账户",
  "xch_00765": "收款银行开户行",
  "xch_00766": "企业名称",
  "xch_00767": "企业付款账号",
  "xch_00768": "最多上传 8 个文件",
  "xch_00769": "预付款",
  "xch_00770": "全款",
  "xch_00771": "尾款",
  "xch_00772": "其他",
  "xch_00773": "您填写的「Payee Bank Name」为空或者不规范，请您提供详细的收款银行开户行后重新提交订单。 ",
  "xch_00774": "您填写的「Payee Bank Account Name（Chinese）」收款账户名称与收款企业名称不一致，当选择公司收款时，账户名需要与公司名保持一致。",
  "xch_00775": "合规审核",
  "xch_00776": "待转账",
  "xch_00777": "收款方信息",
  "xch_00778": "付款方信息",
  "xch_00779": "驳回",
  "xch_00780": "上传前请先提交材料",
  "xch_00781": "支持个人汇款转账",
  "xch_00782": "订单搜索",
  "xch_00783": "支付渠道代码",
  "xch_00784": "智能路由详情",
  "xch_00785": "时间",
  "xch_00786": "活动",
  "xch_00787": "备注",
  "xch_00788": "{0} 下发成功",
  "xch_00789": "收到代付请求",
  "xch_00790": "尝试通过{0}下发",
  "xch_00791": "请求{0}下发，等待响应",
  "xch_00792": "{0}下发失败",
  "xch_00793": "根据路由规则，选择{0}作为首选渠道",
  "xch_00794": "历史流",
  "xch_00795": "链上历史流",
  "xch_00796": "资金流信息流绑定",
  "xch_00797": "链上记录",
  "xch_00798": "延期下发",
  "xch_00799": "由于支付通道繁忙,您的支付请求将稍作延期处理。我们会在资金到位后尽快为您下发,请耐心等待。如有疑问,请联系客户经理。",
  "xch_00800": "根据合作银行反洗钱要求，为保证持续使用我们的服务，请您在48小时内提交收款人{0}的身份证件图像。",
  "xch_00801": "上传中国居民身份证",
  "xch_00802": "人像面",
  "xch_00803": "国徽面",
  "xch_00804": "注意：请不要使用卡套或涂改液遮挡证件",
  "xch_00805": "方向错误",
  "xch_00806": "拍摄不全",
  "xch_00807": "反光强烈",
  "xch_00808": "模糊不清",
  "xch_00809": "根据银行监管要求，该订单需要银行人工审核，请稍候，如有疑问，请联系您的客户经理。",
  "xch_00810": "身份证明",
  "xch_00811": "交易证明",
  "xch_00812": "点击补充材料",
  "xch_00813": "由于地方法规要求需要提供收款人身份证信息，以确保您的汇款安全。",
  "xch_00814": "根据境内银行和监管要求，您需要在后台提供交易证明材料，以确保您的汇款安全。",
  "xch_00815": "根据国内银行及监管要求，您需要在后台提供最新的收款人身份证正反面图像。如果您有任何疑问，请联系我们的客户经理。",
  "xch_00816": "上传交易证明",
  "xch_00817": "签署 NDA 文件",
  "xch_00818": "马上签署",
  "xch_00819": "提交公司材料",
  "xch_00820": "前往提交",
  "xch_00821": "完成调查问卷",
  "xch_00822": "前往填写",
  "xch_00823": "获取信息失败",
  "xch_00824": "👏🏻 欢迎，{0}",
  "xch_00825": "还剩 {0} 步才能解锁所有功能",
  "xch_00826": "还需要完成以下 {0} 个步骤：",
  "xch_00827": "公司管理",
  "xch_00828": "公司资料",
  "xch_00829": "添加公司",
  "xch_00830": "公司注册地",
  "xch_00831": "公司名称",
  "xch_00832": "公司网站",
  "xch_00833": "公司所属行业",
  "xch_00834": "基本信息",
  "xch_00835": "公司注册证明文件",
  "xch_00836": "股权架构图",
  "xch_00837": "最终受益人身份文件",
  "xch_00838": "实际控制人身份信息",
  "xch_00839": "持牌证明文件",
  "xch_00840": "调查问卷1",
  "xch_00841": "调查问卷2",
  "xch_00842": "调查问卷3",
  "xch_00843": "NDA 文件",
  "xch_00844": "上一步",
  "xch_00845": "后续步骤",
  "xch_00846": "填写公司基本信息",
  "xch_00847": "解锁极简货币批量代付平台全部功能",
  "xch_00848": "感谢选择我们成为您中国境内下发合作伙伴，为了更顺利解锁批量代付平台功能，请提供一些基本信息让我们快速了解您。",
  "xch_00849": "签署保密协议",
  "xch_00850": "公司详细资料",
  "xch_00851": "签署完成后，我们会准备 200 CNY 试用资金",
  "xch_00852": "CNY 试用资金可以用于中国境内下发，可做小额资金下发验证。",
  "xch_00853": "查看试用教程",
  "xch_00854": "在线签署",
  "xch_00855": "去签署",
  "xch_00856": "NDA 文件预览",
  "xch_00857": "平台使用教程",
  "xch_00858": "调查问卷",
  "xch_00859": "通过问卷，方便我们更深入地了解您",
  "xch_00860": "根据合规政策要求，我们需要通过调查文件全方位了解您",
  "xch_00861": "线上填写",
  "xch_00862": "填写 Board Resolution Account opening 问卷",
  "xch_00863": "填写 Due Deligence Form 问卷",
  "xch_00864": "填写 AML/CTF/SCP Questionaire 问卷",
  "xch_00865": "线下填写",
  "xch_00866": "下载 Board Resolution Account opening 问卷",
  "xch_00867": "下载  Due Deligence Form 问卷",
  "xch_00868": "下载  AML/CTF/SCP Questionaire Form 问卷",
  "xch_00869": "正式发票（PI）",
  "xch_00870": "用于上传正式发票（Proforma Invoice），包括交易双方名称、地址，名称、材质、数量、型号、单价、总价、交易日期、交易双方签名或者盖章、交易订单号、发票号等信息。",
  "xch_00871": "采购订单（PO）",
  "xch_00872": "用于上传采购订单（Purchase Order），包含交易双方名称、地址，产品名称、材质、数量、型号、单价、总价、交易日期、交易双方签名或盖章、交易订单号等信息。",
  "xch_00873": "物流凭证",
  "xch_00874": "用于上传物流凭证，包括货物运输单据、提单、运单等物流相关文件，确保物流过程的追踪和验证。",
  "xch_00875": "出口报关单",
  "xch_00876": "用于上传出口报关单，包含详细的出口商品信息、报关日期、报关单号、报关双方信息等，以满足出口合规要求。",
  "xch_00877": "沟通记录",
  "xch_00878": "用于上传交易双方的沟通记录，包括电子邮件、聊天记录、电话记录等，以作为交易过程的参考和证据。",
  "xch_00879": "收款人身份证证件",
  "xch_00880": "用于上传收款人的身份证证件，包括护照、身份证等，以验证收款人的身份信息。",
  "xch_00881": "收款人的授权书",
  "xch_00882": "用于上传收款人的授权书，包含授权人签名或盖章，授权内容等，以证明收款人具有合法授权。",
  "xch_00883": "交易双方往期单据",
  "xch_00884": "用于上传交易双方以往的交易单据，作为历史交易记录的参考和验证。",
  "xch_00885": "上下游采购订单证明",
  "xch_00886": "用于上传上下游采购订单证明，确保供应链的完整性和交易的真实性。",
  "xch_00887": "其他材料",
  "xch_00888": "用于上传其他相关材料，以补充和完善交易文件。",
  "xch_00889": "提交",
  "xch_00890": "提交成功",
  "xch_00891": "您的材料尚未提交。您确定要退出吗？",
  "xch_00892": "您的材料提交不完整，请确保至少提交正式发票（PI）或采购订单（PO）中的一种。",
  "xch_00893": "保存成功",
  "xch_00894": "材料审核失败，请重新提交。",
  "xch_00895": "重新提交",
  "xch_00896": "填写基本信息",
  "xch_00897": "邀请他人填写",
  "xch_00898": "付款证明",
  "xch_00899": "获取免费额度失败",
  "xch_00900": "Free Limit",
  "xch_00901": "XC Quota",
  "xch_00902": "今日使用详情",
  "xch_00903": "当您有储备金时，您可以免费使用 xc汇款服务，如有任何疑问，请与您的客户经理联系。",
  "xch_00904": "此订单金额超出限制。如果您有任何疑问，您可以联系我们的客户经理。",
  "xch_00905": "原币种模式",
  "xch_00906": "支持原币种模式汇款",
  "xch_00907": "支持「原币种汇款模式」",
  "xch_00908": "通过我们的原始货币汇款模式，您可以简化交易，避免汇率波动带来的不确定性，并确保您知道转账的确切金额。资金不会因转换程序而延迟，从而加快处理时间，使其成为管理多种货币的企业的理想选择。",
  "xch_00909": "您有未处理的转账信息，是否继续进行操作？",
  "xch_00910": "请修正转账信息",
  "xch_00911": "待处理的转账信息：{0}",
  "xch_00912": "上一个",
  "xch_00913": "下一个",
  "xch_00914": "继续转账",
  "xch_00915": "下载更正后的文件",
  "xch_00916": "收款人姓名格式不正确",
  "xch_00917": "收款人身份证号码格式错误",
  "xch_00918": "收款人电话不能为空",
  "xch_00919": "收款人电话号码格式不正确",
  "xch_00920": "必须提供此信息",
  "xch_00921": "金额格式不正确",
  "xch_00922": "国家代码格式不正确 国籍可以填写两字母代码或三字母代码，如SGP ISO 3166-1 alpha-3 或 alpha-2 例如，SGP 或 SG",
  "xch_00923": "性别格式不正确, 请确保为 Male 或 Female 其中的一种 ",
  "xch_00924": "日期格式不正确, 请确保格式为： 1.YYYY-MM-DD 2.YYYYMMDD 3.YYYY/MM/DD",
  "xch_00925": "日期格式不正确, 请确保为 1.YYYY-MM-DD 2.YYYYMMDD 3.YYYY/MM/DD 其中的一种 并且不可大于当前日期",
  "xch_00926": "资金来源格式不正确, 请确保为 1.Employment income 2.Business Income 3.Part-time income 4.Saving deposits 其中的一种",
  "xch_00927": "付款人证件类型格式不正确, 请确保为 1.idcard 2.passport 3.driver 4.residence 5.workpermit 6.other 其中的一种",
  "xch_00928": "付款人和收款人关系格式不正确, 请确保为 1.Self 2.Spouse 3.Parent 4.Children 5.Sibling 6.Grandparent 7.Grandchildren 其中的一种",
  "xch_00929": "汇款目的格式不正确, 请确保为 1.FamilySupport 2.Salary 其中的一种",
  "xch_00930": "收款人币种格式不正确，请确保币种为CNY",
  "xch_00931": "收款人国籍格式不正确, 请确保为 1.CHN 2.CN 其中的一种",
  "xch_00932": "付款货币格式不正确",
  "xch_00933": "订单号已存在",
  "xch_00934": "当前进度",
  "xch_00935": "银行/数字银行",
  "xch_00936": "支付公司",
  "xch_00937": "汇款公司",
  "xch_00938": "其他机构（请填写）",
  "xch_00939": "公司行业（补充）",
  "xch_00940": "请仔细阅读文档末尾的{0}相关信息以及{1}您的个人信息。",
  "xch_00941": "填写",
  "xch_00942": "展开全文",
  "xch_00943": "已完成，下一步",
  "xch_00944": "已清分",
  "xch_00945": "已退款",
  "xch_00946": "您确定要取消订单吗？",
  "xch_00947": "您确定要退款吗？",
  "xch_00948": "退款",
  "xch_00949": "商业登记证",
  "xch_00950": "公司周年申报表",
  "xch_00951": "公司章程",
  "xch_00952": "公司办公场地照片",
  "xch_00953": "反洗钱/反恐怖融资政策",
  "xch_00954": "独立第三方风险评估报告",
  "xch_00955": "反洗钱主任/合规官的个人简历",
  "xch_00956": "开立账户董事会决议",
  "xch_00957": "公司详细文件",
  "xch_00958": "公司受益人文件",
  "xch_00959": "合规相关文件",
  "xch_00960": "正在处理，预计需要 {0} 秒",
  "xch_00961": "文件审核失败，请重新提交。",
  "xch_00962": "感谢选择极简货币的人民币清结算系统为您提供服务。根据持牌金融机构间合作的合规性要求，需要您根据以下几步的指引提供更多信息，让我们快速了解贵公司的基本情况。",
  "xch_00963": "请输入公司名称",
  "xch_00964": "根据金融机构合规性要求，我们需要收集一些关于贵公司的详细信息，用于评估贵公司的业务情况和资质，确保符合金融监管的要求。所有信息将严格保密并妥善存放。",
  "xch_00965": "发生错误，请稍后重试",
  "xch_00966": "提交成功！",
  "xch_00967": "请点击跳转至待处理的内容或材料进行检查并完成。如果您在此过程中遇到任何问题，请随时联系您的客户经理。",
  "xch_00968": "欢迎回来",
  "xch_00969": "您还剩 {0} 个步骤需要完成",
  "xch_00970": "请跳转至未完成步骤继续填写/上传",
  "xch_00971": "继续填写/上传",
  "xch_00972": "我们建议您按照以下清单准备相应的文件。这将大大节省您的时间。",
  "xch_00973": "1.详细的公司文件",
  "xch_00974": "商业登记证（如有）、公司周年申报表（如有）、公司章程、公司办公场所照片",
  "xch_00975": "2.公司受益人文件",
  "xch_00976": "股权结构图（须由董事或代表签字）最终受益人身份证明文件（持有25%以上股权的最终受益人护照照片、有效地址证明）实际控制人身份信息（如无）持有公司25%以上股权的最终受益人、享有公司最终控制权的自然人的护照照片及有效地址证明）",
  "xch_00977": "3.合规相关文件",
  "xch_00978": "牌照证明（当地监管机构颁发的金融支付许可证或业务许可证）反洗钱/反恐融资政策（请提供最新版本）独立第三方风险评估报告（如有，请提供最新版本）简历反洗钱官/合规官的董事会开户决议反洗钱/反恐融资/制裁调查问卷（待填写）尽职调查表（待填写）",
  "xch_00979": "点击填写在线签字人信息",
  "xch_00980": "可邀请其他授权人士签字",
  "xch_00981": "保存失败，您编辑的内容不再是最新的，请检查内容并重新保存。",
  "xch_00982": "补充",
  "xch_00983": "（请仔细阅读并{0}相关信息，并{1}在文件末尾在线签署您的个人信息）：",
  "xch_00984": "保存并提交预审",
  "xch_00985": "缺少必填字段",
  "xch_00986": "必须由董事或代表签署",
  "xch_00987": "持股25%以上最终受益人的护照照片及有效地址证明",
  "xch_00988": "若无最终受益人持有25%以上股份，需提供公司最终控制人的护照照片及有效地址证明",
  "xch_00989": "当地监管机构颁发的金融支付许可证或业务许可证",
  "xch_00990": "请提供最新版本",
  "xch_00991": "如果有，请提供最新版本",
  "xch_00992": "您可以使用公司的决议，或{0}进行填写和确认。",
  "xch_00993": "我已准备好，立即申请",
  "xch_00994": "下载模板",
  "xch_00995": "欢迎您",
  "xch_00996": "恭喜您已经成功注册并登录极简货币交易管理系统。在正式使用前，按照国际间对于持牌金融机构的合规性要求，您需要在随后的工作流中完成客户准入流程。",
  "xch_00997": "每个步骤都会清楚地指示需要上传或填写的位置。",
  "xch_00998": "请准备以下JPG、PDF、DOC等格式的数字文档。",
  "xch_00999": "内容在{0}自动保存到云端",
  "xch_01000": "填写调查问卷",
  "xch_01001": "您的填写率未达标，确定提交吗？高填写率能提高审核成功率！",
  "xch_01002": "确认提交",
  "xch_01003": "如有",
  "xch_01004": "包含公司名称或品牌名称的现场办公照片",
  "xch_01005": "股权结构图（须由董事或代表签字）、最终受益人身份证明文件（持股25%以上的最终受益人的护照照片及有效地址证明）、实际控制人身份信息（如无最终受益人持股25%以上，提供公司最终控制人的护照照片及有效地址证明）",
  "xch_01006": "请提供最新版本",
  "xch_01007": "如果您持有25%或以上股份，请提供最新版本",
  "xch_01008": "反洗钱/反恐怖融资/制裁问卷",
  "xch_01009": "尽职调查问卷",
  "xch_01010": "需填写",
  "xch_01011": "我们建议您在开始之前按照以下清单准备相应的文件。这将大大节省您的时间。如果您在此过程中遇到任何问题，请随时联系您的客户经理。",
  "xch_01012": "审查进度",
  "xch_01013": "审核完成",
  "xch_01014": "正在审核中",
  "xch_01015": "被拒绝",
  "xch_01016": "邀请失败，请重新邀请或联系您的专属客户经理。",
  "xch_01017": "请填写相关信息并签署",
  "xch_01018": "请输入您的名字！",
  "xch_01019": "请输入您的电子邮件！",
  "xch_01020": "待签署",
  "xch_01021": "待发起签署",
  "xch_01022": "去填写",
  "xch_01023": "您已填写并签署",
  "xch_01024": "等待签署完成",
  "xch_01025": "填写签署人信息",
  "xch_01026": "为保证合作过程中的信息安全，保护双方合法权益，保证合作顺利进行，双方需签署NDA。",
  "xch_01026": "为保证合作过程中的信息安全，保护双方合法权益，保证合作顺利进行，双方需签署NDA。",
  "xch_01027": "根据金融机构合规要求，我们需要通过问卷了解贵公司在业务合规、反洗钱/反恐融资方面的做法和详细信息。这可确保遵守财务法规，也是我们为贵公司提供服务的必要条件。所有信息都将严格保密并安全存储。",
  "xch_01028": "股权结构图（必须由董事或代表签署）",
  "xch_01029": "最终受益人身份证明文件（持有25%以上股份的最终受益人的护照照片和有效地址证明）",
  "xch_01030": "实际控制人身份信息（如无最终受益人持有公司25%以上股份的，提供公司最终控制人的护照照片及有效地址证明）",
  "xch_01031": "牌照证明（当地监管机构颁发的金融支付许可证或营业执照）",
  "xch_01032": "AML/CTF政策（请提供最新版本）",
  "xch_01033": "独立第三方风险评估报告（如有，请提供最新版本）",
  "xch_01034": "反洗钱官员/合规官员的简历",
  "xch_01035": "关于开户的董事会决议",
  "xch_01036": "AML/CTF/制裁调查问卷（待填写）",
  "xch_01037": "尽职调查表（需填写）",
  "xch_01038": "请仔细阅读并{0}相关内容，填写完成后请{1}预审，预审通过后请由授权人进行在线签署。",
  "xch_01039": "保存并提交预审",
  "xch_01040": "发生错误，请刷新并重试。",
  "xch_01041": "签名失败，请联系您的客户经理。",
  "xch_01042": "您已签署",
  "xch_01043": "调查问卷附件",
  "xch_01044": "上传辅助性说明文件（例如：业务流程图）",
  "xch_01045": "调查问卷等待您完成并提交以供初步审查",
  "xch_01046": "您的调查问卷正在初步审核中",
  "xch_01047": "调查问卷初步审核已完成，等待您的签名请求",
  "xch_01048": "立即签署",
  "xch_01049": "顶部",
  "xch_01050": "底部",
  "xch_01051": "预审核不通过，原因: {0}",
  "xch_01052": "请上传图像格式（JPEG、PNG等）或PDF格式的文件。",
  "xch_01053": "全球支付",
  "xch_01054": "订单列表",
  "xch_01055": "批量上传",
  "xch_01056": "支持单次上传。请不要上传与批量添加收款人信息无关的文件。",
  "xch_01057": "客户类型错误",
  "xch_01058": "电子邮件格式错误",
  "xch_01059": "请更正案件信息",
  "xch_01060": "继续上传",
  "xch_01061": "收款方",
  "xch_01062": "付款方",
  "xch_01063": "个人",
  "xch_01064": "实体",
  "xch_01065": "选择客户类型",
  "xch_01066": "个人资料记录了基本的个人信息、联系方式和银行账户数据，确保汇款过程安全、准确。",
  "xch_01067": "企业档案记录了企业详细信息，包括基本信息、注册信息、银行账户、授权文件等，确保汇款合法合规。",
  "xch_01068": "银行帐号",
  "xch_01069": "成立日期",
  "xch_01070": "企业注册地",
  "xch_01071": "公司邮箱",
  "xch_01072": "电话号码",
  "xch_01073": "请选择档案类型",
  "xch_01074": "地址信息",
  "xch_01075": "客户类型",
  "xch_01076": "由于境内银行监管要求，请重新前往 Classic下单，我们会根据订单特性帮您路由至最优路径下发, 资金钱包无需操作调整。\n",
  "xch_01077": "我们建议您在开始之前准备好以下列出的相应文件，这将大大节省您的时间。如果您在此过程中遇到任何问题，请随时联系您的客户经理。",
  "xch_01078": "成功创建的档案",
  "xch_01079": "上传的档案文件",
  "xch_01080": "抱歉，全部档案都创建失败。",
  "xch_01081": "失败的档案详情",
  "xch_01082": "下载失败的表格",
  "xch_01083": "{0}个档案未能处理",
  "xch_01084": "有{0}个档案创建失败，您可以选择根据错误信息检查并进行调整后重新上传。",
  "xch_01085": "所有档案均创建成功",
  "xch_01086": "恭喜，所有档案均已成功创建。你可以前往查看【档案管理】页面进行查看。",
  "xch_01087": "行业详细名称",
  "xch_01088": "请输入行业详细名称",
  "xch_01089": "受益人要素不完整，请填写相关信息",
  "xch_01090": "CNY 模式",
  "xch_01091": "跨币种模式",
  "xch_01092": "原币种模式",
  "xch_01093": "暂时跳过",
  "xch_01094": "缺少材料信息",
  "xch_01095": "详细住所（Addressline1）和邮编（Post Code）必须提供其中的一种",
  "xch_01096": "材料类型",
  "xch_01097": "输入订单号，查询多个以空格进行分隔",
  "xch_01098": "{0}笔转账异常",
  "xch_01099": "全部转账异常",
  "xch_01100": "批处理转账已完成，但以下转账仍处于处理中或存在异常。请查看下方列出的原因",
  "xch_01101": "异常转账详情",
  "xch_01102": "该订单正在由银行处理。请前往订单详情页面跟进状态。如果您有任何疑问，请联系您的客户经理。",
  "xch_01103": " {0}笔转账中",
  "xch_01104": "上传证明材料",
  "xch_01105": "上传客户信息",
  "xch_01106": "客户信息",
  "xch_01107": "证明材料",
  "xch_01108": "上传成功的文件",
  "xch_01109": "上传结果",
  "xch_01110": "提交完成",
  "xch_01111": "您的批次证明材料正在上传和处理中。请稍等。",
  "xch_01112": "您的材料正在上传。",
  "xch_01113": "准备中。如果材料很多，可能需要一些时间。您可以关闭此页面并稍后返回。",
  "xch_01114": "提交成功的材料",
  "xch_01116": "文件夹名称",
  "xch_01117": "客户名称",
  "xch_01118": "点击查看上传指南。",
  "xch_01119": "已补充",
  "xch_01120": "未补充",
  "xch_01121": "该证明材料未关联到订单",
  "xch_01122": "信息",
  "xch_01123": "上传您的文件（zip 格式），其中应包含证明材料。",
  "xch_01124": "支持单次上传。请勿上传与批量添加证明材料信息无关的文件。",
  "xch_01125": "请上传格式类型为 ZIP 的文件，其中应包含证明材料。",
  "xch_01126": "提交失败的材料",
  "xch_01127": "导出失败的证明材料信息",
  "xch_01128": "导出成功。",
  "xch_01129": "当款项性质为不为预付款（DepositPayment）时，需要提供物流公司；多个物流公司时，请使用 | 分隔。",
  "xch_01130": "当款项性质不为预付款（DepositPayment）时，需要提供物流单号；多个物流单号时，请使用 | 分隔。",
  "xch_01131": "请提供完整的工商登记企业中文名称。",
  "xch_01132": "该信息必填：如果收款账户类型为企业，户名需与收款企业名称保持一致；如果收款账户类型为个人，请填写个人收款银行账户名称。",
  "xch_01133": "当收款人账户类型是与收款人同名的企业账户时，必须提供银行分行详细信息，并指定支行。",
  "xch_01134": "当收款账户类型为个人时，需提供收款人的中国大陆身份证件。",
  "xch_01135": "付款类型格式不正确。请确保它是以下之一：\n1.押金支付\n2.全额付款\n3.尾款\n4.其他",
  "xch_01136": "该订单已超过 3 天未更新。发起转账时需要二次确认。如果订单15天内未更新，系统将自动取消订单。",
  "xch_01137": "该订单是在 3 天前创建的。您想继续进行转账吗？",
  "xch_01138": "以下订单已创建超过 3 天。您确认继续转账吗？"
}